@use 'sass:math';

.testimonial-carousel {
    padding: 0 0 u(3.75) 0;

    color: $offBlack;
}

.testimonial-carousel__inner {
    @include grid;
    grid-template-areas: 'content content content content content content';

    @include media('>=tablet') {
        grid-template-areas: '. . . content content content content content content . . .';
    }

    .testimonial-carousel--with-image & {
        grid-template-areas:
            '.       image   image   image   image   .      '
            'content content content content content content';

        @include media('>=tablet') {
            grid-template-areas: '. image image image image image content content content content content content';
        }
    }
}

.testimonial-carousel__image-wrapper {
    display: none;
    margin-bottom: u(2);
    align-self: center;

    .testimonial-carousel--with-image & {
        grid-area: image;
        display: block;
    }
}

.testimonial-carousel__image,
.testimonial-carousel__image > img {
    display: block;
    width: 100%;
    height: auto;

    border-radius: 50%;
}

.testimonial-carousel__content-wrapper {
    grid-area: content;
    align-self: center;
    overflow: hidden;
}

.testimonial-carousel__quote-list {
    display: flex;
    flex-direction: row;
    gap: $gridGutterMobile;
    @include scrollable-x(mandatory);
    overflow-y: hidden;

    /* stylelint-disable selector-max-compound-selectors */
    .mod-no-flexgap & > * + * {
        margin-left: $gridGutterMobile;
    }
    /* stylelint-enable selector-max-compound-selectors */

    @include media('>=tablet') {
        gap: $gridGutterTablet;

        /* stylelint-disable selector-max-compound-selectors */
        .mod-no-flexgap & > * + * {
            margin-left: $gridGutterTablet;
        }
        /* stylelint-enable selector-max-compound-selectors */
    }

    @include media('>=desktop') {
        gap: $gridGutterDesktop;

        /* stylelint-disable selector-max-compound-selectors */
        .mod-no-flexgap & > * + * {
            margin-left: $gridGutterDesktop;
        }
        /* stylelint-enable selector-max-compound-selectors */
    }
}

.testimonial-carousel__quote-list__item {
    position: relative;
    scroll-snap-align: center;
    flex: 0 0 100%;

    &::before,
    &::after {
        @extend %t-quote-decoration;
        display: block;
    }

    &::before {
        height: u(4.25);
        content: '“';
    }

    &::after {
        position: absolute;
        right: 0;
        bottom: 0;
        height: u(3);
        content: '”';
    }

    @include media('>=tablet') {
        padding-left: u(6.25);
        padding-top: u(2);

        &::before {
            position: absolute;
            top: 0;
            left: 0;
        }

        &::after {
            height: u(4.25);
            bottom: 0;
        }
    }
}

.testimonial-carousel__quote-list__quote {
    p {
        @extend %t-h5-alt;
        line-height: math.div(22, 17);
    }

    cite {
        @extend %t-quote-cite;
        display: block;
        margin-top: u(1.5);
        padding-right: u(3);
    }

    @include media('>=tablet') {
        cite {
            margin-top: u(4.5);
        }
    }
}

.testimonial-carousel__controls {
    margin-top: u(3);
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include media('>=tablet') {
        margin-top: u(4.5);
        margin-left: u(6.25);
    }
}

.testimonial-carousel__pagination {

}

.testimonial-carousel__pagination__list {
    display: flex;
    flex-direction: row;
    gap: u(1.5);

    /* stylelint-disable selector-max-compound-selectors */
    .mod-no-flexgap & > * + * {
        margin-left: u(1.5);
    }
    /* stylelint-enable selector-max-compound-selectors */
}

.testimonial-carousel__pagination__item {

}

.testimonial-carousel__pagination__button {
    @include hide-text-alt;
    display: block;
    width: u(1);
    height: u(1);

    background: $grey;

    border-radius: 50%;

    cursor: pointer;

    &.is-active {
        background: $gold;
    }
}

.testimonial-carousel__nav {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: u(2.5);
    margin-top: u(-1);
}

.testimonial-carousel__nav__button {
    color: $gold;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;

    &:hover,
    &:focus {
        opacity: 0.7;
    }

    &:disabled {
        opacity: 0.2;
    }

    .icon {
        width: u(2);
        height: u(2);
    }

    &--prev {
        .icon {
            transform: rotateZ(180deg) translateY(-1px);
        }
    }

    &--next {

    }

    .mod-no-flexgap & + & {
        margin-left: u(2.5);
    }
}

.testimonial-carousel__cta-wrapper {
    margin-top: u(2.75);

    @include media('>=tablet') {
        margin-top: u(5.5);
        margin-left: u(6.25);
    }
}

.testimonial-carousel__cta {
    @extend %t-small-button;
    display: inline-flex;
    align-items: center;
    gap: u(2);

    .icon {
        width: u(3);
        height: u(3);
    }

    /* stylelint-disable selector-max-compound-selectors */
    .mod-no-flexgap & > * + * {
        margin-left: u(2);
    }
    /* stylelint-enable selector-max-compound-selectors */
}
