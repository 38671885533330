@use 'sass:math';

.t-h1,
%t-h1,
.t-h2,
%t-h2,
.t-h2b,
%t-h2b,
.t-h3,
%t-h3,
.t-h4,
%t-h4,
.t-h5,
%t-h5,
.t-nav-primary,
%t-nav-primary {
    font-family: $fontStackBody;
    font-weight: $fontWeightMedium;
    line-height: 1.2;
}

.t-h1-alt,
%t-h1-alt,
.t-h2-alt,
%t-h2-alt,
.t-h2b-alt,
%t-h2b-alt,
.t-h3-alt,
%t-h3-alt,
.t-h4-alt,
%t-h4-alt,
.t-h5-alt,
%t-h5-alt,
.t-h3-h2-alt,
%t-h3-h2-alt,
.t-nav-secondary,
%t-nav-secondary {
    font-family: $fontStackHeading;
    font-weight: $fontWeightSemibold;
    line-height: 1.2;
}

.t-h1-special,
%t-h1-special,
.t-h2-special,
%t-h2-special,
.t-h3-special,
%t-h3-special,
.t-h4-special,
%t-h4-special,
.t-h5-special,
%t-h5-special {
    font-family: $fontStackSpecial;
    font-weight: $fontWeightRegular;
    line-height: 1.1;
}

.t-h1,
%t-h1 {
    font-size: 36px;
    line-height: math.div(41, 36);

    @include media('>=tablet') {
        font-size: 70px;
    }

    @include media('>=desktop') {
        font-size: 80px;
    }
}

.t-h1-alt,
%t-h1-alt {
    font-size: 36px;

    @include media('>=tablet') {
        font-size: 71px;
    }

    @include media('>=desktop') {
        font-size: 81px;
    }
}

// XXX: Sizes for special type variants are not based on any design material
.t-h1-special,
%t-h1-special {
    font-size: 38px;

    @include media('>=tablet') {
        font-size: 76px;
    }

    @include media('>=desktop') {
        font-size: 88px;
    }
}

.t-h2,
%t-h2 {
    font-size: 30px;

    @include media('>=tablet') {
        font-size: 45px;
    }

    @include media('>=desktop') {
        font-size: 60px;
    }
}

// XXX: Minor variation to reduce display size of headline compared to
// `t-h2-special`, ideally remove
.t-h2b,
%t-h2b {
    font-size: 26px;

    @include media('>=tablet') {
        font-size: 40px;
    }

    @include media('>=desktop') {
        font-size: 52px;
    }
}

.t-h2-alt,
%t-h2-alt {
    font-size: 31px;

    @include media('>=tablet') {
        font-size: 46px;
    }

    @include media('>=desktop') {
        font-size: 61px;
    }
}

// XXX: Minor variation to reduce display size of headline compared to
// `t-h2-special`, ideally remove
.t-h2b-alt,
%t-h2b-alt {
    font-size: 27px;

    @include media('>=tablet') {
        font-size: 41px;
    }

    @include media('>=desktop') {
        font-size: 53px;
    }
}

.t-h2-special,
%t-h2-special {
    font-size: 32px;

    @include media('>=tablet') {
        font-size: 50px;
    }

    @include media('>=desktop') {
        font-size: 68px;
    }
}

.t-h3,
%t-h3 {
    font-size: 22px;

    @include media('>=tablet') {
        font-size: 30px;
    }

    @include media('>=desktop') {
        font-size: 40px;
    }
}

.t-h3-alt,
%t-h3-alt {
    font-size: 23px;

    @include media('>=tablet') {
        font-size: 31px;
    }

    @include media('>=desktop') {
        font-size: 37px;
    }
}

.t-h3-special,
%t-h3-special {
    font-size: 25px;

    @include media('>=tablet') {
        font-size: 36px;
    }

    @include media('>=desktop') {
        font-size: 44px;
    }
}

.t-h4,
%t-h4 {
    font-size: 20px;

    @include media('>=tablet') {
        font-size: 26px;
    }

    @include media('>=desktop') {
        font-size: 26px;
    }
}

.t-h4-alt,
%t-h4-alt {
    font-size: 21px;

    @include media('>=tablet') {
        font-size: 27px;
    }

    @include media('>=desktop') {
        font-size: 27px;
    }
}

.t-h4-special,
%t-h4-special {
    font-size: 23px;

    @include media('>=tablet') {
        font-size: 32px;
    }

    @include media('>=desktop') {
        font-size: 32px;
    }
}

.t-h5,
%t-h5 {
    font-size: 16px;

    @include media('>=tablet') {
        font-size: 18px;
    }

    @include media('>=desktop') {
        font-size: 20px;
    }
}

.t-h5-alt,
%t-h5-alt {
    font-size: 17px;

    @include media('>=tablet') {
        font-size: 21px;
    }

    @include media('>=desktop') {
        font-size: 21px;
    }
}

.t-h5-special,
%t-h5-special {
    font-size: 19px;

    @include media('>=tablet') {
        font-size: 25px;
    }

    @include media('>=desktop') {
        font-size: 25px;
    }
}

.t-h3-h2-alt,
%t-h3-h2-alt {
    font-size: 23px;

    @include media('>=tablet') {
        font-size: 46px;
    }

    @include media('>=desktop') {
        font-size: 61px;
    }
}

.t-body,
%t-body,
.t-large-body,
%t-large-body,
.t-small-body,
%t-small-body {
    font-family: $fontStackBody;
    font-weight: $fontWeightRegular;
    line-height: math.div(24, 14);
}

.t-body,
%t-body {
    font-size: 14px;

    @include media('>=tablet') {
        font-size: 16px;
    }

    @include media('>=desktop') {
        font-size: 18px;
    }
}

.t-large-body,
%t-large-body {
    font-size: 20px;

    @include media('>=tablet') {
        font-size: 18px;
    }

    @include media('>=desktop') {
        font-size: 20px;
    }
}

.t-small-body,
%t-small-body {
    font-size: 14px;

    @include media('>=tablet') {
        font-size: 14px;
    }

    @include media('>=desktop') {
        font-size: 16px;
    }
}

.t-button,
%t-button {
    font-family: $fontStackSpecial;
    font-weight: $fontWeightRegular;
    font-size: 22px;
    line-height: 1;

    @include media('>=tablet') {
        font-size: 22px;
    }

    @include media('>=desktop') {
        font-size: 26px;
    }
}

.t-small-button,
%t-small-button {
    font-family: $fontStackSpecial;
    font-weight: $fontWeightRegular;
    font-size: 13px;
    line-height: 1;

    @include media('>=tablet') {
        font-size: 22px;
    }

    @include media('>=desktop') {
        font-size: 26px;
    }
}

.t-tag,
%t-tag {
    font-family: $fontStackBody;
    font-weight: $fontWeightMedium;
    font-size: 14px;
    line-height: 1;

    @include media('>=tablet') {
        font-size: 14px;
    }

    @include media('>=desktop') {
        font-size: 16px;
    }
}

.t-quote-cite,
%t-quote-cite {
    font-family: $fontStackBody;
    font-weight: $fontWeightMedium;
    font-size: 8px;
    line-height: 1;

    @include media('>=tablet') {
        font-size: 14px;
    }

    @include media('>=desktop') {
        font-size: 16px;
    }
}

.t-quote-decoration,
%t-quote-decoration {
    font-family: $fontStackHeading;
    font-weight: $fontWeightSemibold;
    font-size: 38px;
    line-height: 1;
    color: $blue;

    @include media('>=tablet') {
        font-size: 77px;
    }

    @include media('>=desktop') {
        font-size: 77px;
    }
}

.t-nav-primary,
%t-nav-primary {
    font-size: 16px;

    @include media('>=tablet') {
        font-size: 18px;
    }

    @include media('>=desktop') {
        font-size: 20px;
    }
}

.t-nav-secondary,
%t-nav-secondary {
    font-size: 17px;

    @include media('>=tablet') {
        font-size: 15px;
    }

    @include media('>=desktop') {
        font-size: 17px;
    }
}
