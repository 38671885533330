.headline-section {
    @include gutter-padding;
    padding-bottom: u(6.25);

    @include media('>=tablet') {
        padding-top: u(6.25);
        padding-bottom: u(6.25);

        &--tall {
            padding-top: u(10);
            padding-bottom: u(16);
        }
    }
}

.headline-section__inner {
    @include clamp;
    @include grid;
    grid-template-areas: 'cont cont cont cont cont cont';

    > * {
        grid-area: cont;
    }

    @include media('>=tablet') {
        grid-template-areas: '. cont cont cont cont cont cont cont cont cont cont .';
    }
}
