/* poppins-regular - latin */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: $fontWeightRegular;
    src:
        local(''),
        url('../fonts/poppins-v20-latin-regular.woff2') format('woff2'),
        url('../fonts/poppins-v20-latin-regular.woff') format('woff');
    font-display: swap;
}

/* poppins-italic - latin */
@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: $fontWeightRegular;
    src:
        local(''),
        url('../fonts/poppins-v20-latin-italic.woff2') format('woff2'),
        url('../fonts/poppins-v20-latin-italic.woff') format('woff');
    font-display: swap;
}

/* poppins-500 - latin */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: $fontWeightMedium;
    src:
        local(''),
        url('../fonts/poppins-v20-latin-500.woff2') format('woff2'),
        url('../fonts/poppins-v20-latin-500.woff') format('woff');
    font-display: swap;
}

/* poppins-500italic - latin */
@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: $fontWeightMedium;
    src:
        local(''),
        url('../fonts/poppins-v20-latin-500italic.woff2') format('woff2'),
        url('../fonts/poppins-v20-latin-500italic.woff') format('woff');
    font-display: swap;
}

/* lora-600 - latin */
@font-face {
    font-family: 'Lora';
    font-style: normal;
    font-weight: $fontWeightSemibold;
    src:
        local(''),
        url('../fonts/lora-v24-latin-600.woff2') format('woff2'),
        url('../fonts/lora-v24-latin-600.woff') format('woff');
    font-display: swap;
}

/* rozha-one-regular - latin */
@font-face {
    font-family: 'Rozha One';
    font-style: normal;
    font-weight: $fontWeightRegular;
    src:
        local(''),
        url('../fonts/rozha-one-v13-latin-regular.woff2') format('woff2'),
        url('../fonts/rozha-one-v13-latin-regular.woff') format('woff');
    font-display: swap;
}
