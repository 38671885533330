@use 'sass:math';

.simple-tabbed-content {
    padding-top: u(5.25);
    padding-bottom: u(5.25);

    &--dark {
        background: $offBlack;

        color: $white;
    }

    @include media('>=tablet') {
        padding-top: u(13.5);
        padding-bottom: u(13.5);
    }

    @include media('>=desktop') {
        padding-top: u(18.75);
        padding-bottom: u(18.75);
    }
}

.simple-tabbed-content__inner {
    @include gutter-padding;
    @include clamp;
    @include grid;
    grid-template-areas:
        'title title title title title title'
        'heads heads heads heads heads heads'
        'conts conts conts conts conts conts'
        'cta   cta   cta   cta   cta   cta  ';

    @include media('>=tablet') {
        grid-template-areas:
            'title title title title title title title title cta   cta   cta   cta  '
            'heads heads heads heads conts conts conts conts conts conts conts conts';
    }
}

.simple-tabbed-content__title {
    grid-area: title;
    margin-bottom: u(3.5);

    @extend %t-h2-special;
    line-height: math.div(37.5, 30);

    @include media('>=tablet') {
        margin-bottom: u(10);
    }
}

.simple-tabbed-content__cta-wrapper {
    grid-area: cta;
    margin-top: u(3.5);

    @include media('>=tablet') {
        align-self: center;
        justify-self: flex-end;
        margin: 0 0 u(10) 0;
    }
}

.simple-tabbed-content__cta {
    @extend %t-button;
    display: inline-flex;
    align-items: center;
    gap: u(2);

    .icon {
        width: u(3);
        height: u(3);
    }

    /* stylelint-disable selector-max-compound-selectors */
    .mod-no-flexgap & > * + * {
        margin-left: u(2);
    }
    /* stylelint-enable selector-max-compound-selectors */

    @include media('>=tablet') {
        justify-content: flex-end;
    }
}

.simple-tabbed-content__heading-list {
    grid-area: heads;

    @include media('<tablet') {
        @include scrollable-x;
        display: flex;
        flex-direction: row;
        gap: u(2);
        margin: 0 #{-$pageGutterMobile};
        padding: 0 $pageGutterMobile;

        /* stylelint-disable selector-max-compound-selectors */
        .mod-no-flexgap & > * + * {
            margin-left: u(2);
        }
        /* stylelint-enable selector-max-compound-selectors */
    }
}

.simple-tabbed-content__heading-list__item {
    @include media('<tablet') {
        flex: 0 0 auto;
        // XXX: Ensure descenders from the button text aren't clipped
        padding-bottom: u(1);
    }

    @include media('>=tablet') {
        & + & {
            margin-top: u(4);
        }
    }
}

.simple-tabbed-content__heading-list__button {
    opacity: 0.5;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;

    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
    }

    &.is-active {
        opacity: 1;
    }

    &:not(.is-active):hover,
    &:not(.is-active):focus {
        opacity: 0.7;
    }
}

.simple-tabbed-content__heading-list__heading {
    @extend %t-h3-alt;
}

.simple-tabbed-content__content-list-wrapper {
    grid-area: conts;
    overflow: hidden;
}

.simple-tabbed-content__content-list {
    display: flex;
    flex-direction: row;
    @extend %t-body;
    color: $darkGrey;

    .simple-tabbed-content--dark & {
        color: $white;
    }

    @include media('<tablet') {
        // XXX: Gap is u(3) but combines with `padding-bottom` of items
        margin-top: u(2);
    }
}

.simple-tabbed-content__content-list__item {
    @include media('<tablet') {
        display: none;

        &.is-active {
            display: block;
        }
    }

    @include media('>=tablet') {
        visibility: hidden;
        margin-right: -100%;
        width: 100%;
        columns: 2;
        column-gap: $gridGutterTablet;

        &.is-active {
            visibility: visible;
        }
    }

    @include media('>=desktop') {
        column-gap: $gridGutterDesktop;
    }
}
