@use 'sass:math';

.download-promo {
    padding-top: u(5.25);
    padding-bottom: u(5.25);

    background: $offBlack;

    color: $white;

    @include media('>=tablet') {
        padding-top: u(12);
        padding-bottom: u(12);
    }
}

.download-promo__inner {
    @include clamp;
    @include gutter-padding;
    @include grid;

    grid-template-areas:
        'title   title   title   title   title   title  '
        'content content content content content content';

    @include media('>=tablet') {
        grid-template-areas: '. title title title title title content content content content content content';
    }
}

.download-promo__title {
    @extend %t-h2;
    line-height: math.div(69, 60);
    grid-area: title;

    @include media('<tablet') {
        margin-bottom: u(3.75);
    }
}

.download-promo__content-wrapper {
    grid-area: content;
}

.download-promo__standfirst {
    margin-bottom: u(3.75);
}

.download-promo__list {

}

.download-promo__item {
    & + & {
        margin-top: u(2);
    }
}

.download-promo__link {
    @extend %t-button;
    display: inline-flex;
    align-items: center;
    gap: u(3);

    .icon {
        width: u(4);
        height: u(4);
    }

    /* stylelint-disable selector-max-compound-selectors */
    .mod-no-flexgap & > * + * {
        margin-left: u(3);
    }
    /* stylelint-enable selector-max-compound-selectors */
}

.download-promo__meta {
    @extend %t-body;
}
