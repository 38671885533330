.education-banner {

}

.education-banner__inner {
    @include clamp;
    @include gutter-padding;
    padding-top: u(2);
    padding-bottom: u(2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: u(1);

    background: $offBlack;

    color: $white;

    /* stylelint-disable selector-max-compound-selectors */
    .mod-no-flexgap & > * + * {
        margin-left: u(2);
    }
    /* stylelint-enable selector-max-compound-selectors */

    @include media('<tablet') {
        text-align: center;
    }

    @include media('>=tablet') {
        flex-direction: row;
        gap: u(2);
        padding-top: u(4);
        padding-bottom: u(4);
    }
}

.education-banner__copy {
    @extend %t-body;
}

.education-banner__link {
    display: block;
}

.education-banner__logo {
    display: block;
    width: 155px;
    height: 38px;
}
