.mission-card {
    height: 100%;
    @include grid;
    grid-template-areas:
        '.       image   image   image   image   .      '
        'content content content content content content';

    @include media('>=tablet') {
        grid-template-areas: '. content content content content content . image image image image .';

        &--wide {
            grid-template-areas: '. content content content content content content image image image image .';
        }
    }
}

.mission-card__image-wrapper {
    grid-area: image;

    @include media('<tablet') {
        margin: 0 grid-span(1, 6, $gridGutterMobile) u(3.75) grid-span(1, 6, $gridGutterMobile);
    }

    @include media('>=tablet') {
        padding: 0 10%;
    }
}

.mission-card__image {
    display: block;
    width: 100%;
    height: auto;
}

.mission-card__content-wrapper {
    grid-area: content;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
}

.mission-card__title {
    @extend %t-h2-special;
}

.mission-card__headline {
    margin: u(1) 0 u(2.5) 0;

    @include media('>=tablet') {
        margin: u(2) 0 u(5) 0;
    }
}

.mission-card__standfirst {
    @extend %t-body;
    margin: u(2.5) auto 0 0;

    @include media('>=tablet') {
        margin-top: u(3.5);

        .mission-card__headline + & {
            margin-top: 0;
            max-width: 70%;
        }
    }

    @include media('>=desktop') {
        margin-top: u(4.25);
    }
}

.mission-card__cta-wrapper {
    margin: u(3.75) 0 0 0;

    @include media('>=tablet') {
        margin-top: u(5);
    }

    @include media('>=desktop') {
        margin-top: u(6.25);
    }
}

.mission-card__cta {
    @extend %t-button;
    display: inline-flex;
    align-items: center;
    gap: u(2);

    .icon {
        width: u(3);
        height: u(3);
    }

    /* stylelint-disable selector-max-compound-selectors */
    .mod-no-flexgap & > * + * {
        margin-left: u(2);
    }
    /* stylelint-enable selector-max-compound-selectors */
}
