.card-promo {
    padding: u(3.75) 0 u(5) 0;

    &--single {

    }

    &--dark {
        background: $offBlack;

        color: $white;
    }

    &--spectrum {
        @include spectrum-gradient;

        color: $white;
    }

    @include media('>=tablet') {
        padding: u(12.5) 0 u(14) 0;

        &--shallow {
            padding: u(2) 0 u(4) 0;
        }
    }

    @include media('>=desktop') {
        padding: u(16) 0 u(18.5) 0;

        &--shallow {

        }
    }
}

.card-promo__inner {
    @include clamp;
    @include gutter-padding;
}

.card-promo__title {
    @extend %t-h2-special;
    margin-bottom: u(3);

    @include media('>=tablet') {
        margin-bottom: u(6.25);
    }

    @include media('>=desktop') {
        margin-bottom: u(11.5);
    }
}

.card-promo__list {
    display: flex;
    flex-direction: column;
    gap: u(5);

    /* stylelint-disable selector-max-compound-selectors */
    .mod-no-flexgap & > * + * {
        margin-top: u(5);
    }
    /* stylelint-enable selector-max-compound-selectors */

    @include media('>=tablet') {
        flex-flow: row wrap;
        gap: $gridGutterTablet * 2 $gridGutterTablet;

        /* stylelint-disable selector-max-compound-selectors */
        .mod-no-flexgap & > * + * {
            margin-top: 0;
            margin-left: $gridGutterTablet;
        }
        /* stylelint-enable selector-max-compound-selectors */
    }

    @include media('>=desktop') {
        gap: $gridGutterDesktop * 2 $gridGutterDesktop;

        /* stylelint-disable selector-max-compound-selectors */
        .mod-no-flexgap & > * + * {
            margin-left: $gridGutterDesktop;
        }
        /* stylelint-enable selector-max-compound-selectors */
    }
}

.card-promo__item {
    @include media('>=tablet') {
        flex-basis: grid-span(6, 12, $gridGutterTablet);

        .card-promo--single & {
            flex-basis: 100%;
        }
    }

    @include media('>=desktop') {
        flex-basis: grid-span(6, 12, $gridGutterDesktop);

        .card-promo--single & {
            flex-basis: 100%;
        }
    }
}
