@use 'sass:math';

.logo-carousel {
    padding: u(5) 0;

    background: $sandstone;

    overflow: hidden;

    @include media('>=tablet') {
        padding: u(10) 0;
    }

    @include media('>=desktop') {
        padding: u(14) 0;
    }
}

.logo-carousel__inner {
    display: flex;
    flex-direction: row;
}

@keyframes carousel {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-50%);
    }
}

.logo-carousel__list {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: u(4);
    padding-left: u(4);

    animation: carousel 30s linear infinite running;
    will-change: transform;

    &:hover {
        animation-play-state: paused;
    }

    @include media('>=tablet') {
        gap: u(8);
        padding-left: u(8);
    }

    @include media('>=desktop') {
        gap: u(10);
        padding-left: u(10);
    }
}

.logo-carousel__item {
    position: relative;
    width: 80px;

    &::after {
        display: block;
        height: 0;
        padding-top: percentage(math.div(9, 16));

        content: '';
    }

    @include media('>=tablet') {
        width: 150px;
    }

    @include media('>=desktop') {
        width: 150px;
    }
}

.logo-carousel__image {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    object-fit: contain;
}
