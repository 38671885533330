.article {
    padding-bottom: u(4);

    @include media('>=tablet') {
        padding-top: u(8);
        padding-bottom: u(15);

        .header + & {
            padding-top: u(4);
        }

        .header + &--full {
            padding-top: u(2);
        }
    }
}

.article__hero {
    picture,
    img {
        display: block;
        width: 100%;
        height: auto;
    }

    > picture,
    > img {
        margin-bottom: u(3.75);
    }

    @include media('>=tablet') {
        > picture,
        > img {
            margin-bottom: u(10);
        }
    }
}

.article__inner {
    @include clamp;
    @include gutter-padding;
    @include grid;
    grid-template-areas:
        'head head head head head head'
        '. side side side side .'
        'main main main main main main';

    @include media('>=tablet') {
        grid-template-areas:
            '.    head head head head head head head head head head .   '
            '.    side side side side main main main main main main .   ';

        .article--reversed & {
            grid-template-areas:
                '.    head head head head head head head head head head .   '
                '.    main main main main main main side side side side .   ';
        }

        .article--wide & {
            grid-template-areas:
                'head head head head head head head head head head head .   '
                'side side side side .    main main main main main main .   ';
        }

        .article--full & {
            grid-template-areas:
                '.    .    head head head head head head head head .    .   '
                '.    .    main main main main main main main main .    .   ';
        }
    }
}

.article__header {
    grid-area: head;

    margin-bottom: u(3.75);

    .headline {
        margin-top: u(3);
    }

    @include media('>=tablet') {
        margin-bottom: u(10);
    }
}

.article__header__back-button-wrapper {
    @include gutter-padding;
    // XXX: Pull out to the edges of the viewport
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);

    @include media('<tablet') {
        margin-bottom: u(1);
    }

    @include media('>=tablet') {
        height: 0;
    }
}

.article__header__back-button {
    display: block;
    width: u(2);
    height: u(2);

    color: $offBlack;

    .icon {
        width: u(2);
        height: u(2);

        transform: rotateZ(180deg);
    }

    @include media('>=tablet') {
        position: relative;
        top: u(1.5);
        width: u(4);
        height: u(4);

        .icon {
            width: u(4);
            height: u(4);
        }
    }
}

.article__sidebar {
    grid-area: side;

    @include media('>=tablet') {
        padding-left: 5%;
        padding-right: 5%;
    }
}

.article__sidebar__image,
.article__sidebar__image > img {
    display: block;
    width: 100%;
    height: auto;

    border-radius: 50%;
}

.article__sidebar__image {
    margin-bottom: u(3.75);
}

.article__main {
    grid-area: main;
    @extend %t-body;
}

.article__title,
.article__subtitle {
    margin-bottom: u(3.75);

    color: $offBlack;
}

.article__title {
    @extend %t-h2-special;
}

.article__subtitle {
    @extend %t-h3;
}

.article__meta {
    @extend %t-h5-alt;
    margin-bottom: u(3.75);

    color: $offBlack;

    @include media('>=tablet') {
        margin-bottom: u(5);
    }
}

.article__block {
    position: relative;

    & + & {
        margin-top: u(6.25);
    }

    &--full {
        // XXX: Pull out to the edges of the viewport
        margin-left: calc(50% - 50vw);
        margin-right: calc(50% - 50vw);
    }

    &--full + &--full {
        margin-top: 0;
    }

    @include media('>=tablet') {
        & + & {
            margin-top: u(8);
        }

        &--full + &--full {
            margin-top: 0;
        }
    }
}

.article__block-share-link {
    position: absolute;
    top: u(0.5);
    right: u(0.5);
    display: none;
    align-items: center;
    justify-content: center;
    width: u(3);
    height: u(3);

    background: $white;

    border-radius: 50%;

    color: $offBlack;

    .icon {
        width: u(2);
        height: u(2);
    }

    .article__block:hover & {
        display: flex;
    }
}

.article__block__inner {
    @include clamp;
}

.article__cta {
    margin: 0 #{-$pageGutterMobile};

    &--background {
        padding: $pageGutterMobile;

        background: $offBlack;

        color: $white;
    }

    &--centred {
        text-align: center;
    }

    &--left {
        margin-left: 0;
        margin-right: 0;
    }

    @include media('>=tablet') {
        &--background {
            margin-left: 0;
            // XXX: Pull out by one grid column
            margin-right: grid-pull(1, 8, $gridGutterTablet);
            padding: $gridGutterTablet;

            /* stylelint-disable max-nesting-depth */
            .article__block + & {
                margin-top: u(4);
            }
            /* stylelint-enable max-nesting-depth */
        }

        &--centred {
            padding: u(14) 0;
        }
    }

    @include media('>=desktop') {
        &--background {
            // XXX: Pull out by one grid column
            margin-right: grid-pull(1, 8, $gridGutterDesktop);
            padding: $gridGutterDesktop;
        }

        &--centred {
            padding: u(14) 0;
        }
    }
}

.article__cta__title {
    @extend %t-h4;
    margin-bottom: u(1.75);

    .article__cta--centred & {
        @extend %t-h3;
    }

    @include media('>=tablet') {
        margin-bottom: u(3.5);
    }
}

.article__cta__text {
    @extend %t-body;
}

.article__cta__link-wrapper {
    margin-top: u(3.5);

    @include media('>=tablet') {
        margin-top: u(3.5);
    }
}

.article__cta__link,
.article__cta__button {
    @extend %t-button;
    display: inline-flex;
    align-items: center;
    gap: u(2);

    .icon {
        width: u(3);
        height: u(3);
    }

    /* stylelint-disable selector-max-compound-selectors */
    .mod-no-flexgap & > * + * {
        margin-left: u(2);
    }
    /* stylelint-enable selector-max-compound-selectors */
}

.article__cta__button {
    padding: u(2);

    background: $offBlack;

    color: $white;

    &:hover,
    &:focus-visible {
        background: $pink;

        color: $offBlack;
    }

    .article__cta--background & {
        background: lighten($offBlack, 5%);

        transition: all 0.2s ease-in-out;

        &:hover,
        &:focus-visible {
            background: $pink;

            color: $offBlack;
            text-decoration: none;
        }
    }
}

.article__cta__newsletter {
    margin-top: u(2);
}

.article__downloads {

}

.article__image {

}

.article__image__image,
.article__image__image > img {
    display: block;
    width: 100%;
    height: auto;
}

.article__testimonials {
    @include media('>=tablet') {
        // XXX: Pull left and right by two grid columns
        margin-left: grid-pull(2, 8, $gridGutterTablet);
        margin-right: grid-pull(2, 8, $gridGutterTablet);
    }

    @include media('>=desktop') {
        // XXX: Pull left and right by two grid columns
        margin-left: grid-pull(2, 8, $gridGutterDesktop);
        margin-right: grid-pull(2, 8, $gridGutterDesktop);
    }
}

.article__headline-block {

}

.article__headline-block__heading {
    color: $offBlack;
}

.article__video {

}

.article__video-grid {

}

.article__custom-html-block {

}

.article__text-block,
.article__text-and-image-block {
    .article__headline-block + & {
        margin-top: u(3);
    }

    @include media('>=tablet') {
        .article__headline-block + & {
            margin-top: u(4);
        }
    }
}

.article__text-and-image-block {
    @include media('>=tablet') {
        .article__block:not(.article__headline-block) + & {
            margin-top: u(10);
        }
    }
}

.article__text-and-image-block__grid {
    @include grid;
    grid-template-areas:
        '.     image image image image .    '
        'text  text  text  text  text  text ';

    @include media('>=tablet') {
        grid-template-areas: 'image image image image text text text text';
        grid-template-columns: repeat(8, 1fr);

        .article__text-and-image-block--reversed & {
            grid-template-areas: 'text text text text image image image image';
        }
    }

    @include media('>=desktop') {
        grid-template-columns: repeat(8, 1fr);
    }
}

.article__text-and-image-block__image-wrapper {
    grid-area: image;
}

.article__text-and-image-block__image,
.article__text-and-image-block__image > img {
    display: block;
    width: 100%;
    height: auto;

    border-radius: 50%;
}

.article__text-and-image-block__text-wrapper {
    grid-area: text;

    @include media('<tablet') {
        margin-top: u(4);
    }
}
