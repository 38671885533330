@use 'sass:math';

.video-card {
    display: flex;
    flex-direction: column;
    height: 100%;

    border: 1px solid $borderGrey;

    background: $white;

    color: $offBlack;
}

.video-card__embed {
    position: relative;

    &::after {
        display: block;

        content: '';
    }

    iframe {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &--16_9::after {
        padding-top: percentage(math.div(9, 16));
    }

    &--16_10::after {
        padding-top: percentage(math.div(10, 16));
    }

    &--4_3::after {
        padding-top: percentage(math.div(3, 4));
    }

    &--square::after {
        padding-top: 100%;
    }

    @include media('>=tablet') {
        position: relative;
        z-index: 1;
    }
}

.video-card__content-wrapper {
    padding: u(2.5);
    flex-grow: 1;

    @extend %t-body;
    color: $darkGrey;

    @include media('>=tablet') {
        padding: u(3.75);
    }
}
