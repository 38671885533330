@mixin hide-text-alt() {
    font: 0/0 a; /* stylelint-disable-line font-family-no-missing-generic-family-keyword */
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}

@mixin spectrum-gradient() {
    background-color: hsla(217, 68%, 60%, 1);
    background-image:
        radial-gradient(at 36% 44%, hsla(302, 100%, 83%, 1) 0, transparent 50%),
        radial-gradient(at 93% 50%, hsla(162, 100%, 37%, 1) 0, transparent 50%),
        radial-gradient(at 0% 0%, hsla(302, 100%, 83%, 1) 0, transparent 50%),
        radial-gradient(at 1% 29%, hsla(302, 100%, 83%, 1) 0, transparent 50%),
        radial-gradient(at 11% 89%, hsla(12, 97%, 62%, 1) 0, transparent 50%),
        radial-gradient(at 87% 21%, hsla(217, 89%, 60%, 1) 0, transparent 50%),
        radial-gradient(at 84% 77%, hsla(45, 99%, 59%, 1) 0, transparent 50%),
        radial-gradient(at 100% 100%, hsla(48, 98%, 75%, 1) 0, transparent 50%),
        radial-gradient(at 0% 100%, hsla(13, 98%, 63%, 1) 0, transparent 50%);
}

@mixin scrollable-x($type: proximity) {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    overscroll-behavior-x: contain;
    scrollbar-width: none;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    scroll-snap-type: x $type;

    &::-webkit-scrollbar {
        display: none;
    }

    @include media('>=desktop') {
        touch-action: pan-x;
    }
}

/* stylelint-disable value-no-vendor-prefix */
@mixin line-clamp($lines: 3) {
    display: -webkit-box;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
/* stylelint-enable value-no-vendor-prefix */

@mixin clamp() {
    max-width: $layoutMaxWidth;
    margin-left: auto;
    margin-right: auto;
}

@mixin gutter-margin() {
    margin-left: $pageGutterMobile;
    margin-right: $pageGutterMobile;

    @include media('>=tablet') {
        margin-left: $pageGutterTablet;
        margin-right: $pageGutterTablet;
    }

    @include media('>=desktop') {
        margin-left: $pageGutterDesktop;
        margin-right: $pageGutterDesktop;
    }
}

@mixin gutter-padding($vertical: false) {
    padding-left: $pageGutterMobile;
    padding-right: $pageGutterMobile;

    @if $vertical {
        padding-top: $pageGutterMobile;
        padding-bottom: $pageGutterMobile;
    }

    @include media('>=tablet') {
        padding-left: $pageGutterTablet;
        padding-right: $pageGutterTablet;

        @if $vertical {
            padding-top: $pageGutterTablet;
            padding-bottom: $pageGutterTablet;
        }
    }

    @include media('>=desktop') {
        padding-left: $pageGutterDesktop;
        padding-right: $pageGutterDesktop;

        @if $vertical {
            padding-top: $pageGutterDesktop;
            padding-bottom: $pageGutterDesktop;
        }
    }
}

@mixin break-out-of-gutters() {
    margin-left: #{-$pageGutterMobile};
    margin-right: #{-$pageGutterMobile};

    @include media('>=tablet') {
        margin-left: #{-$pageGutterTablet};
        margin-right: #{-$pageGutterTablet};
    }

    @include media('>=desktop') {
        margin-left: #{-$pageGutterDesktop};
        margin-right: #{-$pageGutterDesktop};
    }
}

@mixin grid() {
    display: grid;
    grid-template-columns: repeat($gridColumnsMobile, 1fr);
    grid-template-rows: auto;
    grid-column-gap: $gridGutterMobile;

    @include media('>=tablet') {
        grid-template-columns: repeat($gridColumnsTablet, 1fr);
        grid-column-gap: $gridGutterTablet;
    }

    @include media('>=desktop') {
        grid-template-columns: repeat($gridColumnsDesktop, 1fr);
        grid-column-gap: $gridGutterDesktop;
    }
}

@mixin highlight($alt: false) {
    position: relative;
    z-index: 1;
    display: inline-block;

    &::before {
        position: absolute;
        top: -0.08em;
        right: -0.3em;
        bottom: -0.4em;
        left: -0.6em;
        z-index: -1;

        background: url('../svg/highlight_animated.svg') 0 0 no-repeat;
        background-size: 100% 100%;

        pointer-events: none;
        content: '';

        @if $alt {
            background-image: url('../svg/highlight_alt_animated.svg');
        }
    }
}
