.nav {
    @include media('<tablet') {
        position: fixed;
        z-index: $z-nav;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        padding-top: u(16.5);

        background: $offBlack;

        color: $white;
        overflow-y: auto;
        overscroll-behavior: contain;
        -webkit-overflow-scrolling: touch;
    }

    @include media('>=tablet') {
        &--light {
            color: $white;
        }
    }
}

.nav__inner {
    @include clamp;

    @include media('>=tablet') {
        margin: 0;
    }
}

.nav__primary-list {
    display: flex;
    flex-direction: column;

    @include media('>=tablet') {
        flex-flow: row wrap;
        justify-content: flex-end;
        gap: 0 u(2);

        /* stylelint-disable selector-max-compound-selectors */
        .mod-no-flexgap & > * + * {
            margin-left: u(2);
        }
        /* stylelint-enable selector-max-compound-selectors */
    }
}

.nav__primary-list__item {
    @include media('<tablet') {
        &.is-open {
            background: $white;

            color: $offBlack;
        }
    }

    @include media('>=tablet') {
        position: relative;

        &.is-open {
            background: $offBlack;

            color: $white;
        }

        .nav--light &.is-open {
            background: $white;

            color: $offBlack;
        }
    }
}

.nav__primary-list__item__inner {
    @include media('<tablet') {
        padding: u(3) $pageGutterMobile;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}

.nav__primary-list__link {
    @extend %t-nav-primary;

    @include media('>=tablet') {
        display: block;
        padding: u(1);

        &:hover,
        &:active,
        &:focus {
            text-decoration: none;
        }
    }
}

.nav__primary-list__toggle {
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    .icon {
        width: u(2.5);
        height: u(2.5);
    }

    .icon--plus {

    }

    .icon--minus {
        display: none;
    }

    .nav__primary-list__item.is-open & {
        .icon--plus {
            display: none;
        }

        .icon--minus {
            display: block;
        }
    }

    @include media('>=tablet') {
        display: none;
    }
}

.nav__secondary-list {
    display: flex;
    flex-direction: column;

    @include media('<tablet') {
        display: none;
        padding: 0 $pageGutterMobile $pageGutterMobile $pageGutterMobile;
        gap: u(2);

        /* stylelint-disable selector-max-compound-selectors */
        .mod-no-flexgap & > * + * {
            margin-top: u(2);
        }
        /* stylelint-enable selector-max-compound-selectors */

        .nav__primary-list__item.is-open & {
            display: flex;
        }
    }

    @include media('>=tablet') {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;

        background: $offBlack;

        color: $white;

        .nav--light & {
            background: $white;

            color: $offBlack;
        }

        .nav__primary-list__item.is-open & {
            display: flex;
        }
    }
}

.nav__secondary-list__item {
    padding: u(1) 0;

    @include media('>=tablet') {
        padding: u(1);
    }
}

.nav__secondary-list__link {
    @extend %t-nav-secondary;
    display: block;

    line-height: 1;

    @include media('>=tablet') {
        white-space: nowrap;
    }
}
