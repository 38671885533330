.custom-select {
    position: relative;

    border: 1px solid rgba($offBlack, 0.2);

    &::after {
        display: block;
        position: absolute;
        top: 50%;
        right: u(2);
        width: 0;
        height: 0;
        margin-top: -2px;

        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid currentColor;

        pointer-events: none;
        content: '';
    }
}

.custom-select__select {
    display: block;
    width: 100%;
    padding: u(1.75) u(2);

    border: 0;
    outline: 0;

    background: transparent;

    font: inherit;
    color: inherit;
    appearance: none;
}
