.theme-card {
    height: 100%;

    transition: all 0.2s ease-in-out;
    text-align: center;
    color: $white;

    @include media('>=tablet') {
        &--full.is-active {
            background: $green;

            color: $offBlack;

            /* stylelint-disable max-nesting-depth */
            @supports (--accent-colour: white) {
                background: var(--accent-colour, $green);
            }
            /* stylelint-enable max-nesting-depth */
        }
    }
}

.theme-card__link {
    display: block;

    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
    }

    @include media('>=tablet') {
        .theme-card--full & {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: u(9) u(3.75) u(12) u(3.75);
        }
    }
}

.theme-card__header {
    position: relative;

    border-radius: 50%;
    outline: 0 solid transparent;

    overflow: hidden;
    transition: all 0.2s ease-in-out;
    // XXX: Ensure border radius clipping on Safari
    isolation: isolate;

    .theme-card--minimal.is-active & {
        outline: u(0.5) solid $green;

        @supports (--accent-colour: white) {
            outline-color: var(--accent-colour, $green);
        }

        @include media('>=tablet') {
            outline-width: u(1);
        }
    }
}

.theme-card__title-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;

    background: rgba($black, 0.2);
}

.theme-card__title {
    @extend %t-h4-special;
    text-transform: lowercase;
    color: $white;
}

.theme-card__video-wrapper {
    position: relative;
    z-index: 1;
    width: 100%;
    margin: 0 auto;

    &::after {
        display: block;
        padding-top: 100%;

        content: '';
    }

    .theme-card--full & {
        width: u(25);
    }

    @include media('>=tablet') {
        .theme-card--full & {
            width: u(32);
        }
    }
}

.theme-card__video {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;

    object-fit: cover;
}

.theme-card__standfirst {
    display: none;
    @extend %t-small-body;
    margin: u(4) u(2) 0 u(2);

    @include media('>=tablet') {
        display: block;
    }
}

.theme-card__cta {
    @extend %t-button;
    display: none;

    @include media('>=tablet') {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: u(2);
        margin: u(6.5) 0 0 0;

        .icon {
            width: u(3);
            height: u(3);
        }

        /* stylelint-disable selector-max-compound-selectors */
        .mod-no-flexgap & > * + * {
            margin-left: u(2);
        }
        /* stylelint-enable selector-max-compound-selectors */
    }
}
