.newsletter-form {
    display: flex;
    flex-direction: column;
    gap: u(4);

    /* stylelint-disable selector-max-compound-selectors */
    .mod-no-flexgap & > * + * {
        margin-top: u(4);
    }
    /* stylelint-enable selector-max-compound-selectors */

    @include media('>=tablet') {
        flex-direction: row;
        align-items: center;
        gap: u(3.75);

        /* stylelint-disable selector-max-compound-selectors */
        .mod-no-flexgap & > * + * {
            margin-top: 0;
            margin-left: u(3.75);
        }
        /* stylelint-enable selector-max-compound-selectors */

        &--column {
            flex-direction: column;
            align-items: flex-start;
        }
    }
}

.newsletter-form__title {
    @extend %t-h3;
    line-height: 1.4;

    &--small {
        @extend %t-h5;
    }
}

.newsletter-form__standfirst {
    @extend %t-body;
}

.newsletter-form__form {
    @include media('>=tablet') {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: u(3.75);

        /* stylelint-disable selector-max-compound-selectors */
        .mod-no-flexgap & > * + * {
            margin-left: u(3.75);
        }
        /* stylelint-enable selector-max-compound-selectors */
    }
}

.newsletter-form__input {
    @extend %t-h5-alt;
    display: block;
    padding: u(1) 0;

    border-bottom: 1px solid $white;

    @include media('<tablet') {
        width: 100%;
    }

    @include media('>=tablet') {
        min-width: 13em;
    }
}

.newsletter-form__buttons {
    @include media('<tablet') {
        margin-top: u(2.25);
    }
}

.newsletter-form__button {
    @extend %t-button;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    gap: u(1.25);

    opacity: 1;
    transition: all 0.2s ease;
    cursor: pointer;

    &:hover,
    &:focus {
        opacity: 0.7;
    }

    .article__block & {
        padding: u(2);

        &:hover,
        &:focus {
            background: $pink;

            opacity: 1;
            color: $offBlack;
        }
    }

    /* stylelint-disable selector-max-compound-selectors */
    .mod-no-flexgap & > * + * {
        margin-left: u(1.25);
    }
    /* stylelint-enable selector-max-compound-selectors */
}
