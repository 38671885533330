.collaborator-gallery {
    background: $offBlack;

    color: $white;
}

.collaborator-gallery__inner {
    @include clamp;
    @include gutter-padding;
    position: relative;
}

.collaborator-gallery__video {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    object-fit: cover;
}

.collaborator-gallery__image-list,
.collaborator-gallery__image-item,
.collaborator-gallery__image,
.collaborator-gallery__image > img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
}

.collaborator-gallery__image-item {
    transition: opacity 0.5s ease-in-out;
    opacity: 0;

    &.is-active {
        opacity: 1;
    }
}

.collaborator-gallery__image,
.collaborator-gallery__image > img {
    width: 100%;
    height: 100%;
    z-index: 1;
    object-fit: cover;
}

.collaborator-gallery__overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;

    background-color: rgba($black, 0.4);

    transition: background-color 0.25s ease-in-out;

    &.is-full {
        background-color: $black;
    }
}

.collaborator-gallery__content-wrapper {
    @include grid;
    grid-template-areas:
        'head head head head head head'
        'main main main main main main';
    position: relative;
    z-index: 3;
    padding: u(5.75) 0;

    .collaborator-gallery--with-content & {
        grid-template-areas:
            'head head head head head head'
            'main main main main main main'
            'cont cont cont cont cont cont';
    }

    @include media('>=tablet') {
        grid-template-areas: 'head head head head head main main main main main main .   ';
        align-items: center;
        padding: u(9.75) 0;

        .collaborator-gallery--with-content & {
            grid-template-areas:
                'head head head head head head head head head head head .   '
                'main main main main main cont cont cont cont cont cont .   ';
            align-items: normal;
            padding: u(25) 0 u(13) 0;
        }
    }

    @include media('>=1200px') {
        grid-template-areas: '. head head head head main main main main main main .';

        .collaborator-gallery--with-content & {
            grid-template-areas:
                '.    head head head head head head head head head head .   '
                '.    main main main main main cont cont cont cont cont .   ';
        }
    }

    @include media('>=desktop') {
        padding: u(11.75) 0;
    }
}

.collaborator-gallery__header {
    grid-area: head;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @include media('>=tablet') {
        flex-direction: column;
        justify-content: normal;
        align-items: flex-start;

        .collaborator-gallery--with-content & {
            margin-bottom: u(8);
        }
    }
}

.collaborator-gallery__title {
    @extend %t-h2;

    @include media('>=tablet') {
        margin: u(5) 0;

        .collaborator-gallery--with-content & {
            margin: 0;
        }
    }

    @include media('>=desktop') {
        margin: u(5.5) 0;
    }
}

.collaborator-gallery__cta {
    @extend %t-button;
    flex-shrink: 0;
    display: inline-flex;
    align-items: center;
    gap: u(2);

    .icon {
        width: u(3);
        height: u(3);
    }

    /* stylelint-disable selector-max-compound-selectors */
    .mod-no-flexgap & > * + * {
        margin-left: u(2);
    }
    /* stylelint-enable selector-max-compound-selectors */

    @include media('<tablet') {
        .short {

        }

        .long {
            display: none;
        }
    }

    @include media('>=tablet') {
        // XXX: Give element 0 height so it doesn't impact centering of title
        height: 0;

        .short {
            display: none;
        }

        .long {

        }
    }
}

.collaborator-gallery__main {
    grid-area: main;
    margin-top: u(3.5);

    @include media('>=tablet') {
        .collaborator-gallery--with-content & {
            margin-top: 0;
        }
    }
}

.collaborator-gallery__list {
    @extend %t-h3-h2-alt;
}

.collaborator-gallery__item {
    & + & {
        margin-top: u(1.25);
    }

    @include media('>=tablet') {
        & + & {
            margin-top: u(4);
        }
    }

    @include media('>=desktop') {
        & + & {
            margin-top: u(6);
        }
    }
}

.collaborator-gallery__link {
    text-transform: lowercase;
    opacity: 0.3;
    transition: all 0.2s ease-in-out;

    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
    }

    &.is-active {
        @include highlight($alt: true);
        opacity: 1;
    }

    .collaborator-gallery--with-content & {
        &:hover,
        &:active,
        &:focus {
            opacity: 1;
        }
    }
}

.collaborator-gallery__content {
    @extend %t-body;
    grid-area: cont;

    @include media('<tablet') {
        margin-top: u(5);
    }
}

.collaborator-gallery__content-list {
    display: flex;
    flex-direction: row;
}

.collaborator-gallery__content-item {
    margin-right: -100%;
    width: 100%;

    transition: opacity 0.5s ease-in-out 0;
    opacity: 0;
    pointer-events: none;

    &.is-active {
        opacity: 1;
        transition-delay: 0.25s;
        pointer-events: initial;
    }
}
