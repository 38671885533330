.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $z-modal;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $pageGutterMobile;

    background: rgba(0, 0, 0, 0.8);

    transition: opacity 0.5s ease-in-out;
    opacity: 0;

    &.is-visible {
        opacity: 1;
    }

    @include media('>=tablet') {
        padding: $pageGutterTablet;
    }

    @include media('>=desktop') {
        padding: $pageGutterDesktop;
    }
}

.modal__inner {
    position: relative;
    width: calc(100vw - #{$pageGutterMobile * 2});
    height: calc(100vh - #{u(32)});

    background: $white;

    transition: all 0.5s ease-in-out;
    opacity: 0;
    transform: translateY(u(2));

    &.is-visible {
        opacity: 1;
        transform: none;
    }

    @supports (height: 100dvh) {
        height: calc(100dvh - #{u(32)});
    }

    @include media('>=tablet') {
        width: 640px;
        height: 640px;
        max-width: calc(100vw - #{$pageGutterTablet * 2});
        max-height: calc(100vh - #{$pageGutterTablet * 2});

        @supports (max-height: 100dvh) {
            max-height: calc(100dvh - #{$pageGutterTablet * 2});
        }
    }

    @include media('>=desktop') {
        max-width: calc(100vw - #{$pageGutterDesktop * 2});
        max-height: calc(100vh - #{$pageGutterDesktop * 2});

        @supports (max-height: 100dvh) {
            max-height: calc(100dvh - #{$pageGutterDesktop * 2});
        }
    }
}

.modal__close {
    position: absolute;
    z-index: $z-nav-toggle;
    top: u(1);
    right: u(1);
    width: u(4.25);
    height: u(4.25);
    display: flex;
    align-items: center;
    justify-content: center;

    background: $white;

    border-radius: 50%;

    transition:
        background-color 0.2s ease-in-out,
        transform 0.2s ease-in-out;
    color: $black;
    cursor: pointer;

    &:focus-visible,
    &:hover {
        transform: scale(1.05);
    }

    .icon {
        width: u(2);
        height: u(2);
    }

    @include media('>=tablet') {
        top: u(2);
        right: u(2);
        width: u(7.5);
        height: u(7.5);

        .icon {
            width: u(3);
            height: u(3);
        }
    }
}

.modal__content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    overflow-y: auto;
    overscroll-behavior: contain;

    iframe {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
    }
}
