// Colours ////////////////////////////////////////////////////////////////////
$black: #000;
$white: #fff;

$offBlack: #0f1c1c;
$gold: #fecd2f;
$pink: #ffa9fc;
$yellow: #fee480;
$green: #00bc83;
$orange: #fd6c44;
$blue: #4285f4;
$sandstone: #efe8e6;

$grey: #d3d1d0;
$midGrey: #939393;
$darkGrey: #7e7e7e;
$borderGrey: #d5d5d5;

$ui-highlight: $green;
$ui-highlight-2: $pink;


// Fonts //////////////////////////////////////////////////////////////////////
$fontStackBody: 'Poppins', Helvetica, sans-serif;
$fontStackHeading: 'Lora', Baskerville, Times, 'Times New Roman', serif;
$fontStackSpecial: 'Rozha One', Baskerville, Times, 'Times New Roman', serif;


// Units //////////////////////////////////////////////////////////////////////
$baseFontSize: 16px;
$baseLineHeightRatio: 1.2;

$fontWeightRegular: 400;
$fontWeightMedium: 500;
$fontWeightSemibold: 600;


// Layout and include-media config ////////////////////////////////////////////
$layoutMaxWidth: 1920px;

$pageGutterMobile: 30px;
$pageGutterTablet: 75px;
$pageGutterDesktop: 120px;

$gridGutterMobile: 20px;
$gridGutterTablet: 40px;
$gridGutterDesktop: 50px;

$gridColumnsMobile: 6;
$gridColumnsTablet: 12;
$gridColumnsDesktop: 12;

$mobile: 320;
$mobilePx: $mobile * 1px;
$tablet: 1024;
$tabletPx: $tablet * 1px;
$desktop: 1664;
$desktopPx: $desktop * 1px;

$breakpoints: (
    mobile: $mobilePx,
    tablet: $tabletPx,
    desktop: $desktopPx,
);


// Layers /////////////////////////////////////////////////////////////////////
$z-absolute-header: 10;
$z-nav: 20;
$z-nav-toggle: 30;
$z-admin-toolbar: 15;
$z-modal: 40;


// Exports ////////////////////////////////////////////////////////////////////
/* stylelint-disable property-no-unknown, property-case */
:export {
    mobile: $mobile;
    mobilePx: $mobilePx;
    tablet: $tablet;
    tabletPx: $tabletPx;
    desktop: $desktop;
    desktopPx: $desktopPx;
}
/* stylelint-enable property-no-unknown, property-case */
