.theme-carousel {
    background: $offBlack;

    color: $white;
}

.theme-carousel__inner {
    @include clamp;
    padding: u(5) 0;

    @include media('>=tablet') {
        padding: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
}

.theme-carousel__header {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0 $pageGutterMobile;

    @include media('>=tablet') {
        padding: 0 $pageGutterTablet;
    }

    @include media('>=desktop') {
        padding: 0 $pageGutterDesktop;
    }
}

.theme-carousel__heading {
    @extend %t-h1-special;
    white-space: nowrap;

    @include media('>=tablet') {
        flex-basis: 100%;
        margin-bottom: u(5.75);
    }

    @include media('>=desktop') {
        margin-bottom: u(3);
    }
}

.theme-carousel__cta {
    @extend %t-button;
}

.theme-carousel__nav {
    display: flex;
    flex-direction: row;
    gap: u(2);

    /* stylelint-disable selector-max-compound-selectors */
    .mod-no-flexgap & > * + * {
        margin-left: u(2);
    }
    /* stylelint-enable selector-max-compound-selectors */
}

.theme-carousel__nav__button {
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;

    &:hover,
    &:focus {
        opacity: 0.7;
    }

    &:disabled {
        opacity: 0.2;
    }

    .icon {
        width: u(4);
        height: u(4);
    }

    &--prev {
        .icon {
            transform: rotateZ(180deg);
        }
    }

    &--next {

    }
}

.theme-carousel__main {
    @include media('<tablet') {
        margin: u(3) 0 0 0;
    }

    @include media('>=tablet') {
        overflow-x: hidden;
    }

    @include media('>=desktop') {
        // XXX: Pull out to the right-hand side of the viewport
        margin-right: calc(50% - 50vw);
    }
}

.theme-carousel__list {
    padding: 0 $pageGutterMobile;
    display: flex;
    flex-direction: row;
    gap: u(2.5);
    @include scrollable-x;

    @include media('<tablet') {
        /* stylelint-disable selector-max-compound-selectors */
        .mod-no-flexgap & > * + * {
            margin-left: u(2.5);
        }
        /* stylelint-enable selector-max-compound-selectors */
    }

    @include media('>=tablet') {
        padding: 0;
        gap: 0;
    }
}

.theme-carousel__item {
    // XXX: Assumes 3 items, with `auto` last item expands despite `1 1`
    flex: 1 1 33.33%;
    scroll-snap-align: center;

    @include media('>=tablet') {
        max-width: 450px;
        scroll-snap-align: start;
    }
}
