.card-grid {
    background: $sandstone;

    &--alt-bg {
        background: $white;
    }
}

.card-grid__inner {
    @include clamp;
    @include gutter-padding;
    padding-top: u(5.25);
    padding-bottom: u(5.25);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: $gridGutterMobile;

    /* stylelint-disable selector-max-compound-selectors */
    .mod-no-flexgap & > * + * {
        margin-left: $gridGutterMobile;
    }
    /* stylelint-enable selector-max-compound-selectors */

    @include media('>=tablet') {
        padding-top: u(7.5);
        padding-bottom: u(7.5);
        gap: $gridGutterTablet;

        /* stylelint-disable selector-max-compound-selectors */
        .mod-no-flexgap & > * + * {
            margin-left: $gridGutterTablet;
        }
        /* stylelint-enable selector-max-compound-selectors */

        .card-grid--with-sidebar-left & {
            flex-direction: row;
            align-items: flex-start;
        }

        .card-grid--with-sidebar-top & {
            gap: u(6.25);
        }
    }

    @include media('>=desktop') {
        padding-top: u(11.25);
        padding-bottom: u(11.25);
        gap: $gridGutterDesktop;

        /* stylelint-disable selector-max-compound-selectors */
        .mod-no-flexgap & > * + * {
            margin-left: $gridGutterDesktop;
        }
        /* stylelint-enable selector-max-compound-selectors */

        .card-grid--with-sidebar-top & {
            gap: u(7);
        }
    }
}

.card-grid__header {
    @include media('>=tablet') {
        margin-bottom: u(2.5);
    }
}

.card-grid__title {
    @extend %t-h2;

    color: $offBlack;

    @include media('<tablet') {
        margin-bottom: u(2);
    }
}

.card-grid__standfirst {
    margin-top: u(2);
    @extend %t-body;

    @include media('>=tablet') {
        margin-top: u(6.25);
    }
}

.card-grid__sidebar {
    flex-basis: 100%;

    @include media('>=tablet') {
        .card-grid--with-sidebar-left & {
            flex-basis: grid-span(4, 12, $gridGutterTablet);
        }

        .card-grid--with-sidebar-left.card-grid--narrow & {
            flex-basis: grid-span(3, 12, $gridGutterTablet);
        }
    }

    @include media('>=desktop') {
        .card-grid--with-sidebar-left & {
            flex-basis: grid-span(4, 12, $gridGutterDesktop);
        }

        .card-grid--with-sidebar-left.card-grid--narrow & {
            flex-basis: grid-span(3, 12, $gridGutterDesktop);
        }
    }
}

.card-grid__nav {

}

.card-grid__nav__form {
    @include media('>=tablet') {
        display: none;
    }
}

.card-grid__nav__list {
    @include media('<tablet') {
        display: none;
    }

    @include media('>=tablet') {
        display: flex;
        flex-direction: column;
        gap: u(3.75);

        /* stylelint-disable selector-max-compound-selectors */
        .mod-no-flexgap & > * + * {
            margin-left: u(3.75);
        }
        /* stylelint-enable selector-max-compound-selectors */

        .card-grid--with-sidebar-top & {
            flex-direction: row;
            gap: u(4.25);
        }
    }
}

.card-grid__nav__item {

}

.card-grid__nav__link {
    @extend %t-h5;
    color: $midGrey;
    transition: all 0.2s ease-in-out;

    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
    }

    &:hover,
    &:focus,
    &.is-active {
        color: $offBlack;
    }

    &.is-active {
        text-decoration: underline;
        text-underline-offset: u(1.25);
    }
}

.card-grid__list {
    display: flex;
    flex-flow: row wrap;
    gap: $gridGutterMobile;
    flex-basis: 100%;

    /* stylelint-disable selector-max-compound-selectors */
    .mod-no-flexgap & > * + * {
        margin-left: $gridGutterMobile;
    }
    /* stylelint-enable selector-max-compound-selectors */

    @include media('>=tablet') {
        gap: $gridGutterTablet;

        /* stylelint-disable selector-max-compound-selectors */
        .mod-no-flexgap & > * + * {
            margin-left: $gridGutterTablet;
        }
        /* stylelint-enable selector-max-compound-selectors */

        .card-grid--with-sidebar-left & {
            flex-basis: grid-span(8, 12, $gridGutterTablet);
        }

        .card-grid--with-sidebar-left.card-grid--narrow & {
            flex-basis: grid-span(9, 12, $gridGutterTablet);
        }
    }

    @include media('>=desktop') {
        gap: $gridGutterDesktop;

        /* stylelint-disable selector-max-compound-selectors */
        .mod-no-flexgap & > * + * {
            margin-left: $gridGutterDesktop;
        }
        /* stylelint-enable selector-max-compound-selectors */

        .card-grid--with-sidebar-left & {
            flex-basis: grid-span(8, 12, $gridGutterDesktop);
        }

        .card-grid--with-sidebar-left.card-grid--narrow & {
            flex-basis: grid-span(9, 12, $gridGutterDesktop);
        }
    }
}

.card-grid__item {
    flex: 0 0 100%;

    &--full {
        flex-basis: 100% !important;
    }

    @include media('>=tablet') {
        flex-basis: grid-span(4, 12, $gridGutterTablet);

        .card-grid--with-sidebar-left & {
            flex-basis: grid-span(4, 8, $gridGutterTablet);
        }

        .card-grid--with-sidebar-left.card-grid--narrow & {
            flex-basis: grid-span(3, 9, $gridGutterTablet);
        }
    }

    @include media('>=desktop') {
        flex-basis: grid-span(4, 12, $gridGutterDesktop);

        .card-grid--with-sidebar-left & {
            flex-basis: grid-span(4, 8, $gridGutterDesktop);
        }

        .card-grid--with-sidebar-left.card-grid--narrow & {
            flex-basis: grid-span(3, 9, $gridGutterDesktop);
        }
    }
}
