.people-card {
    height: 100%;
    @include grid;
    grid-template-areas:
        'avatars avatars avatars avatars avatars avatars'
        'content content content content content content';

    @include media('>=tablet') {
        grid-template-areas: '. avatars avatars avatars avatars avatars . content content content content .';
    }
}

.people-card__avatar-wrapper {
    grid-area: avatars;
}

.people-card__avatar-list {
    display: flex;
    flex-flow: row wrap;
    gap: u(3.75);

    /* stylelint-disable selector-max-compound-selectors */
    .mod-no-flexgap & > * {
        margin: u(1.25);
    }
    /* stylelint-enable selector-max-compound-selectors */

    @include media('>=tablet') {
        gap: u(3.75) u(5);
    }
}

.people-card__avatar-list__item {
    position: relative;
    flex: 0 0 calc((100% - #{u(3.75) * 2}) * (1 / 3));

    background: $grey;

    border-radius: 50%;

    overflow: hidden;

    &::after {
        display: block;
        padding-top: 100%;

        content: '';
    }

    @include media('>=tablet') {
        flex-basis: calc((100% - #{u(5) * 2}) * (1 / 3));
    }
}

.people-card__avatar-list__image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;

    > img {
        display: block;
        width: 100%;
        height: auto;

        object-fit: cover;
    }
}

.people-card__content-wrapper {
    grid-area: content;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;

    @include media('<tablet') {
        margin-top: u(4.5);
    }
}

.people-card__title {
    @extend %t-h2-special;
}

.people-card__standfirst {
    @extend %t-body;
    margin: u(2.5) 0 0 0;

    @include media('>=tablet') {
        margin-top: u(3.5);
    }

    @include media('>=desktop') {
        margin-top: u(4.25);
    }
}

.people-card__cta-wrapper {
    margin: u(3.75) 0 0 0;

    @include media('>=tablet') {
        margin-top: u(5);
    }

    @include media('>=desktop') {
        margin-top: u(6.25);
    }
}

.people-card__cta {
    @extend %t-button;
    display: inline-flex;
    align-items: center;
    gap: u(2);

    .icon {
        width: u(3);
        height: u(3);
    }

    /* stylelint-disable selector-max-compound-selectors */
    .mod-no-flexgap & > * + * {
        margin-left: u(2);
    }
    /* stylelint-enable selector-max-compound-selectors */
}
