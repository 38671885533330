.empty-card {
    @extend %t-h5;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: u(3.75);
    min-height: 200px;

    background: rgba($grey, 0.5);

    text-align: center;
    color: $darkGrey;

    @include media('>=tablet') {
        min-height: 330px;
    }

    @include media('>=desktop') {
        min-height: 480px;
    }
}
