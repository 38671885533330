@use 'sass:math';

.person-card {
    position: relative;

    text-align: center;
    transition: opacity 0.2s ease-in-out;

    &.is-dimmed {
        opacity: 0.5;
    }

    @include media('<tablet') {
        margin-bottom: u(3.75);
    }
}

.person-card__image-wrapper {
    position: relative;
    width: 50%;
    margin: 0 auto u(2.5) auto;

    background: $grey;

    border-radius: 50%;

    overflow: hidden;

    &::after {
        display: block;
        padding-top: 100%;

        content: '';
    }

    @include media('>=tablet') {
        width: 55%;
    }
}

.person-card__image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;

    > img {
        display: block;
        width: 100%;
        height: auto;

        object-fit: cover;
    }
}

.person-card__name {
    @extend %t-h5;
    margin-bottom: u(1.25);
}

.person-card__role {
    @extend %t-tag;
    line-height: math.div(22, 16);
}

.person-card__body {
    display: none;
    margin-top: u(6.25);

    @extend %t-body;
    color: $darkGrey;
    text-align: left;

    .person-card.is-open & {
        display: block;
    }
}

.person-card__trigger {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;

    cursor: pointer;

    &:focus-visible {
        box-shadow: 0 u(0.5) 0 0 $ui-highlight;
    }

    .icon {
        display: none;
    }

    .person-card.is-open & {
        right: auto;
        bottom: auto;
        width: u(4.5);
        height: u(4.5);
        display: flex;
        align-items: center;
        justify-content: center;

        background: $offBlack;

        border-radius: 50%;

        color: $white;

        &:focus-visible {
            box-shadow: none;
        }

        .icon {
            display: block;
            width: u(1.5);
            height: u(1.5);
        }
    }

    @include media('>=tablet') {
        /* stylelint-disable max-nesting-depth */
        .person-card.is-open & {
            width: u(7.5);
            height: u(7.5);

            .icon {
                width: u(2.5);
                height: u(2.5);
            }
        }
        /* stylelint-enable max-nesting-depth */
    }
}
