.tagline {
    display: block;
    width: 100%;
    height: auto;
}

.tagline__inner {
    position: relative;

    &::after {
        display: block;
        padding-top: 100%;

        content: '';
    }
}

.tagline__layer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;

    &:nth-child(1) {
        animation: spin 15s linear infinite running forwards;
    }

    &:nth-child(2) {
        animation: spin 30s linear infinite running forwards reverse;
    }
}

@keyframes spin {
    0% {
        transform: none;
    }

    100% {
        transform: rotateZ(360deg);
    }
}
