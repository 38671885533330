.footer {
    padding-top: u(5);
    padding-bottom: u(6);

    background: $offBlack;

    color: $white;

    @include media('<tablet') {
        &--extended {
            padding-bottom: u(12);
        }
    }

    @include media('>=tablet') {
        padding-top: u(10);
        padding-bottom: u(7.25);

        &--extended {
            min-height: 100vh;

            background: url('../svg/butterfly_gray_alt.svg') center right no-repeat $offBlack;

            /* stylelint-disable max-nesting-depth */
            @supports (min-height: 100dvh) {
                min-height: 100dvh;
            }
            /* stylelint-enable max-nesting-depth */
        }
    }

    @include media('>=desktop') {
        padding-top: u(14);
        padding-bottom: u(9);
    }
}

.footer__inner {
    @include clamp;
    @include gutter-padding;
    @include grid;
    grid-template-areas:
        'head head head head head head'
        'con1 con1 con1 con1 con1 con1'
        'con2 con2 con2 con2 con2 con2'
        'subs subs subs subs subs subs';

    @include media('>=tablet') {
        grid-template-areas:
            'head head head head head head head head head head head head'
            'tag  tag  .    .    con1 con1 con1 con1 con2 con2 con2 .   '
            'subs subs subs subs subs subs subs subs subs subs subs subs';

        .footer--extended & {
            grid-template-areas:
                '.    head head head head head head head head head head .   '
                '.    con1 con1 con1 con1 .    subs subs subs subs subs .   '
                '.    con2 con2 con2 con2 .    subs subs subs subs subs .   ';
        }
    }
}

.footer__headline {
    grid-area: head;

    @include media('>=tablet') {
        margin-bottom: u(12);
    }
}

.footer__tagline {
    grid-area: tag;

    @include media('<tablet') {
        display: none;
    }

    @include media('>=tablet') {
        .footer--extended & {
            display: none;
        }
    }
}

.footer__contact-primary {
    grid-area: con1;

    @include media('<tablet') {
        margin-top: u(6);
    }
}

.footer__contact-secondary {
    grid-area: con2;

    @include media('<tablet') {
        margin-top: u(4);
    }

    @include media('>=tablet') {
        .footer--extended & {
            margin-top: u(6);
        }
    }
}

.footer__heading {
    @extend %t-h3;
}

.footer__copy {
    margin-top: u(2);

    a {
        @extend %t-button;
        word-break: break-all;
    }

    @include media('>=tablet') {
        margin-top: u(5);
    }
}

.footer__small-copy {
    @extend %t-body;

    @include media('<tablet') {
        margin-top: u(2);
    }
}

.footer__social-list {
    margin-top: u(3.75);
}

.footer__newsletter {
    grid-area: subs;
    margin-top: u(4.5);
    padding-top: u(4.5);

    border-top: 1px solid $white;

    @include media('>=tablet') {
        margin-top: u(6.5);
        padding-top: u(6.5);

        .footer--extended & {
            border-top: 0;
            margin-top: 0;
            padding-top: 0;
        }
    }
}

.footer__navigation-list {
    margin-top: u(2);

    @include media('>=tablet') {
        margin-top: u(5);
    }
}

.footer__navigation-item {
    & + & {
        margin-top: u(1);
    }
}

.footer__navigation-link {
    @extend %t-button;
}
