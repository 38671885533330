.card-carousel {
    &--alt-bg {
        background: $sandstone;
    }

    &--pull-up {
        margin-top: u(-4);
    }

    @include media('>=tablet') {
        &--pull-up {
            margin-top: u(-15);
        }
    }
}

.card-carousel__inner {
    @include clamp;
    padding-top: u(5.25);
    padding-bottom: u(5.25);

    @include media('>=tablet') {
        padding-top: u(12);
        padding-bottom: u(12);
    }
}

.card-carousel__header {
    @include gutter-padding;
    @include grid;
    grid-template-areas:
        'ttl ttl ttl ttl ttl ttl'
        'cta cta cta nav nav nav'
        'sta sta sta sta sta sta';
    margin-bottom: u(2);

    @include media('>=tablet') {
        grid-template-areas:
            'ttl ttl ttl ttl ttl ttl ttl ttl cta cta nav nav'
            'sta sta sta sta sta sta sta sta sta sta sta sta';
        margin-bottom: u(7.5);

        .card-carousel--without-nav & {
            grid-template-areas:
                'ttl ttl ttl ttl ttl ttl ttl ttl .   .   cta cta'
                'sta sta sta sta sta sta sta sta sta sta sta sta';
        }
    }
}

.card-carousel__title {
    @extend %t-h2-special;
    grid-area: ttl;

    color: $offBlack;

    @include media('<tablet') {
        margin-bottom: u(2);
    }
}

.card-carousel__cta {
    grid-area: cta;
    align-self: center;

    a {
        @extend %t-button;
    }

    @include media('>=tablet') {
        .card-carousel--without-nav & {
            text-align: right;
        }
    }
}

.card-carousel__nav {
    grid-area: nav;
    align-self: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: u(2.5);

    @include media('>=tablet') {
        .card-carousel--without-nav & {
            display: none;
        }
    }
}

.card-carousel__nav__button {
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;

    &:hover,
    &:focus {
        opacity: 0.7;
    }

    &:disabled {
        opacity: 0.2;
    }

    .icon {
        width: u(4);
        height: u(4);
    }

    &--prev {
        .icon {
            transform: rotateZ(180deg);
        }
    }

    &--next {

    }

    .mod-no-flexgap & + & {
        margin-left: u(2.5);
    }
}

.card-carousel__standfirst {
    grid-area: sta;
    margin-top: u(2);
    @extend %t-body;

    @include media('>=tablet') {
        margin-top: u(6.25);
    }
}

.card-carousel__list {
    @include gutter-padding;
    display: flex;
    flex-direction: row;
    gap: $gridGutterMobile;
    @include scrollable-x;

    /* stylelint-disable selector-max-compound-selectors */
    .mod-no-flexgap & > * + * {
        margin-left: $gridGutterMobile;
    }
    /* stylelint-enable selector-max-compound-selectors */

    @include media('>=tablet') {
        gap: $gridGutterTablet;

        /* stylelint-disable selector-max-compound-selectors */
        .mod-no-flexgap & > * + * {
            margin-left: $gridGutterTablet;
        }
        /* stylelint-enable selector-max-compound-selectors */
    }

    @include media('>=desktop') {
        gap: $gridGutterDesktop;

        /* stylelint-disable selector-max-compound-selectors */
        .mod-no-flexgap & > * + * {
            margin-left: $gridGutterDesktop;
        }
        /* stylelint-enable selector-max-compound-selectors */
    }
}

.card-carousel__item {
    scroll-snap-align: center;
    flex: 0 0 100%;

    @include media('<tablet') {
        max-width: 350px;
    }

    @include media('>=tablet') {
        flex-basis: grid-span(4, 12, $gridGutterTablet);
        min-width: 300px;

        &--large {
            flex-basis: 100%;
        }
    }

    @include media('>=desktop') {
        flex-basis: grid-span(4, 12, $gridGutterDesktop);

        &--large {
            flex-basis: 100%;
        }
    }
}
