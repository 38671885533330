.page-header {
    @include gutter-padding;
    padding-bottom: u(2);

    @include media('>=tablet') {
        padding-top: u(6);
        padding-bottom: u(10);
    }
}

.page-header__inner {
    @include clamp;
    @include grid;
    grid-template-areas: 'cont cont cont cont cont cont';

    > * {
        grid-area: cont;
    }

    @include media('>=tablet') {
        grid-template-areas: '. cont cont cont cont cont cont cont cont cont . .';
    }
}

.page-header__content {

}

.page-header__title {

}

.page-header__headline {
    .page-header__title + & {
        margin-top: u(3);
    }
}
