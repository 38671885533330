.header {
    position: relative;
    z-index: 2;

    &--dark {
        background: $offBlack;

        color: $white;
    }

    @include media('>=tablet') {
        &--absolute {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            z-index: $z-absolute-header;

            background: transparent;
        }
    }
}

.header__inner {
    @include clamp;
    display: flex;
    justify-content: space-between;
    padding: u(7) $pageGutterMobile;

    @include media('>=tablet') {
        padding: u(5.75) u(6.25) u(4.75) u(6.25);
        align-items: center;
    }
}

.header__toggle-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: $z-nav-toggle;

    @include media('>=tablet') {
        display: none;
    }
}

.header__toggle-wrapper__inner {
    @include clamp;
    position: relative;
}

.header__toggle {
    position: absolute;
    top: u(6.25);
    right: $pageGutterMobile;
    width: u(4.25);
    height: u(4.25);
    display: flex;
    align-items: center;
    justify-content: center;

    background: $ui-highlight-2;

    border-radius: 50%;

    transition:
        background-color 0.2s ease-in-out,
        transform 0.2s ease-in-out;
    color: $black;
    cursor: pointer;

    &:focus-visible,
    &:hover {
        transform: scale(1.05);
    }

    .icon {
        width: u(2);
        height: u(2);
    }

    .icon--menu {

    }

    .icon--close {
        display: none;
    }

    .header.is-open & {
        background: $white;

        .icon--menu {
            display: none;
        }

        .icon--close {
            display: block;
        }
    }

    @include media('>=tablet') {
        top: u(5);
        right: u(5);
        width: u(7.5);
        height: u(7.5);

        .icon {
            width: u(3);
            height: u(3);
        }
    }
}

.header__nav {
    @include media('<tablet') {
        display: none;

        .header.is-open & {
            display: block;
        }
    }

    @include media('>=tablet') {
        margin-left: u(4);
        flex-grow: 1;
        display: flex;
        justify-content: flex-end;
    }
}
