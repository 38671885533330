@use 'sass:math';

.video-hero {
    position: relative;
    z-index: 1;

    background: $offBlack;

    color: $white;

    @include media('<tablet') {
        &--with-banner {
            margin-bottom: u(8);
        }
    }
}

.video-hero__promo-banner {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: u(8);
    padding: 0 $pageGutterMobile;

    background: $black;

    color: $white;

    @include media('<tablet') {
        position: absolute;
        top: 100%;
        right: 0;
        left: 0;
    }

    @include media('>=tablet') {
        height: u(6);
        padding: 0 $pageGutterTablet;
    }

    @include media('>=desktop') {
        padding: 0 $pageGutterDesktop;
    }
}

.video-hero__promo-banner__link {
    @extend %t-small-body;
    display: inline-flex;
    flex-direction: row;
    align-items: center;

    line-height: 1;

    .icon {
        width: u(2);
        height: u(2);
        margin-left: u(1);
    }
}

.video-hero__inner {
    @include clamp;
    position: relative;
}

.video-hero__headline-wrapper {
    @include gutter-padding;
    @include grid;
    grid-template-areas: 'h h h h h h';
    padding-bottom: 60px;

    @include media('>=tablet') {
        grid-template-areas: '. h h h h h h h h h h .';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 2;
    }
}

.video-hero__headline-wrapper__inner {
    /* stylelint-disable value-no-vendor-prefix */
    grid-area: h;
    height: 100%;
    height: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    /* stylelint-enable value-no-vendor-prefix */
}

.video-hero__headline {
    opacity: 0;
    animation: fadeIn 0.5s ease-in-out 0.5s 1 running forwards;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.video-hero__embed {
    position: relative;

    &::after {
        display: block;

        content: '';
    }

    iframe {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &--16_9::after {
        padding-top: percentage(math.div(9, 16));
    }

    &--16_10::after {
        padding-top: percentage(math.div(10, 16));
    }

    &--4_3::after {
        padding-top: percentage(math.div(3, 4));
    }

    &--square::after {
        padding-top: 100%;
    }

    @include media('>=tablet') {
        position: relative;
        z-index: 1;
    }
}
