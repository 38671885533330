html {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    font: $fontWeightMedium #{$baseFontSize}/$baseLineHeightRatio $fontStackBody;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;

    background: $ui-highlight;

    color: $offBlack;
}

input {
    padding: 0;
    font: inherit;
    border: 0;
    border-radius: 0;
    outline: 0;
    background: transparent;
    color: inherit;
}

/* stylelint-disable selector-no-qualifying-type */
input[type='text'],
input[type='email'] {
    appearance: none;

    &:disabled {
        background-color: initial;
    }
}
/* stylelint-enable selector-no-qualifying-type */

button {
    padding: 0;

    outline: 0;
    border: 0;
    border-radius: 0;

    background: transparent;

    cursor: pointer;
    font: inherit;
    color: inherit;
    text-align: inherit;
    appearance: none;

    &:disabled {
        cursor: default;
    }
}

a {
    outline: 0;

    color: inherit;
    text-decoration: none;

    &:hover,
    &:active,
    &:focus {
        text-decoration: underline;
    }
}

.cky-btn-revisit-wrapper {
    width: 30px !important;
    height: 30px !important;

    .cky-btn-revisit img {
        max-width: 20px !important;
        margin: 4px 0 0 0 !important;
    }
}
