/* stylelint-disable max-nesting-depth */
.u-rich-text {
    h2,
    h3,
    h4,
    h5 {
        color: $offBlack;

        &:not(:first-child) {
            margin-top: 1em;
        }
    }

    h2 {
        @extend %t-h4;
    }

    h3 {
        @extend %t-h5;
    }

    ol,
    ul {
        padding-left: 0.9em;
    }

    ol {
        list-style-type: decimal;
    }

    ul {
        list-style-type: disc;

        li::marker {
            color: $gold;
        }
    }

    li {
        display: list-item;
        padding-left: 0.5em;
    }

    a {
        text-decoration: underline;

        &:hover,
        &:focus-visible {
            text-decoration: none;
        }
    }

    .button {
        @extend %t-button;
        padding: u(2);

        background: lighten($offBlack, 5%);

        transition: all 0.2s ease-in-out;
        text-decoration: none;
        color: $white;

        &::after {
            display: inline-block;
            margin-left: 0.5em;

            content: '\2794';
        }

        &:hover,
        &:focus-visible {
            background: $pink;

            color: $offBlack;
            text-decoration: none;
        }
    }

    * + p,
    * + ul,
    * + ol,
    * + li {
        margin-top: 2em;
    }
}
/* stylelint-enable max-nesting-depth */

[data-loading] {
    position: relative;

    &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        background: rgba($white, 0.5);

        content: '';
    }
}
