@use 'sass:math';

.headline {
    @extend %t-h2b;
    line-height: math.div(60, 45);

    strong {
        @extend %t-h2b-alt;
        @include highlight;
    }

    &--small {
        @extend %t-h3;

        strong {
            @extend %t-h3-alt;
        }
    }

    &--smaller {
        @extend %t-h4;

        strong {
            @extend %t-h4-alt;
        }
    }
}
