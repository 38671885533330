// TODO: Attempt to avoid this specific figure which relies on the length of
// the headings in the current content
$interimHeadingFontSize: 4.2vw;

.tabbed-content {
    @include gutter-padding;
    padding-top: u(5.25);
    padding-bottom: u(5.25);

    &--alt-bg {
        background: $sandstone;
    }

    &--content-below {
        padding-top: u(0.5);
        padding-bottom: u(4);
    }

    @include media('>=tablet') {
        padding-top: u(13.5);
        padding-bottom: u(13.5);

        &--content-below {
            padding-top: u(7);
            padding-bottom: u(7.5);
        }
    }

    @include media('>=desktop') {
        padding-top: u(18.75);
        padding-bottom: u(18.75);

        &--content-below {
            padding-top: u(7);
            padding-bottom: u(8.75);
        }
    }
}

.tabbed-content__inner {
    @include clamp;
    @include grid;
    grid-template-areas:
        'pref pref pref pref pref pref'
        'head head head head head head'
        'stnd stnd stnd stnd stnd stnd'
        'cont cont cont cont cont cont';

    @include media('>=tablet') {
        grid-template-areas:
            '.    .    head head head head .    stnd stnd stnd stnd stnd'
            'cont cont cont cont cont cont cont cont cont cont cont cont';
    }

    @include media('>=desktop') {
        grid-template-areas:
            '.    .    head head head head .    stnd stnd stnd stnd .    '
            'cont cont cont cont cont cont cont cont cont cont cont cont ';
    }

    .tabbed-content--wide-headings & {
        @include media('>=tablet') {
            grid-template-areas:
                'head head head head head head head head stnd stnd stnd stnd'
                'cont cont cont cont cont cont cont cont cont cont cont cont';
        }

        @include media('>=desktop') {
            grid-template-areas:
                'head head head head head head head .    stnd stnd stnd stnd'
                'cont cont cont cont cont cont cont cont cont cont cont cont';
        }
    }

    .tabbed-content--content-below & {
        @include media('>=tablet') {
            grid-template-areas:
                'head head head head head head head head head head head head'
                '.    .    .    stnd stnd stnd stnd stnd stnd stnd stnd stnd'
                'cont cont cont cont cont cont cont cont cont cont cont cont';
        }

        @include media('>=desktop') {
            grid-template-areas:
                'head head head head head head head head head head head head'
                '.    .    .    stnd stnd stnd stnd stnd stnd stnd stnd stnd'
                'cont cont cont cont cont cont cont cont cont cont cont cont';
        }
    }
}

.tabbed-content__heading-prefix {
    grid-area: pref;
    @extend %t-h1;

    @include media('>=tablet') {
        display: none;
    }
}

.tabbed-content__heading-list {
    grid-area: head;
    @extend %t-h1-alt;

    @include media('<tablet') {
        @include scrollable-x;
        display: flex;
        flex-direction: row;
        gap: u(2);
        margin: 0 #{-$pageGutterMobile};
        padding: 0 $pageGutterMobile;

        /* stylelint-disable selector-max-compound-selectors */
        .mod-no-flexgap & > * + * {
            margin-left: u(2);
        }
        /* stylelint-enable selector-max-compound-selectors */
    }

    // Attempt to constrain font size to prevent layout breakage
    @include media('>=tablet', '<desktop') {
        font-size: $interimHeadingFontSize;
    }
}

.tabbed-content__heading-list__item {
    @include media('<tablet') {
        flex: 0 0 auto;
        // XXX: Ensure descenders from the button text aren't clipped
        padding-bottom: u(1);
    }

    @include media('>=tablet') {
        & + & {
            margin-top: u(2);
        }

        .tabbed-content--wide-headings & + & {
            margin-top: u(4);
        }
    }

    @include media('>=desktop') {
        & + & {
            margin-top: u(3);
        }

        .tabbed-content--wide-headings & + & {
            margin-top: u(6);
        }
    }
}

.tabbed-content__heading-list__button {
    display: inline-block;

    opacity: 1;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
    color: $grey;

    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
    }

    &.is-active {
        color: $offBlack;
    }

    &:not(.is-active):hover,
    &:not(.is-active):focus {
        opacity: 0.7;
    }
}

.tabbed-content__heading-list__heading {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.3em;

    /* stylelint-disable selector-max-compound-selectors */
    .mod-no-flexgap & > * + * {
        margin-left: 0.3em;
    }
    /* stylelint-enable selector-max-compound-selectors */
}

.tabbed-content__heading-list__prefix {
    @extend %t-h1;

    @include media('<tablet') {
        display: none;
    }

    @include media('>=tablet') {
        visibility: hidden;

        .tabbed-content__heading-list__button.is-active & {
            visibility: visible;
        }
    }

    // Attempt to constrain font size to prevent layout breakage
    @include media('>=tablet', '<desktop') {
        font-size: $interimHeadingFontSize;
    }
}

.tabbed-content__heading-list__icon {
    display: block;
    width: 1.2em;
    height: auto;
    position: relative;
    top: 0.08em;
    margin: 0 0.2em;

    transition: all 0.2s ease-in-out;
    filter: grayscale(1);
    opacity: 0.1;

    .tabbed-content__heading-list__button.is-active & {
        filter: none;
        opacity: 1;
    }
}

.tabbed-content__heading-list__suffix {
    .tabbed-content--with-highlights .tabbed-content__heading-list__button.is-active & {
        @include highlight($alt: true);
    }
}

.tabbed-content__primary-content-list {
    grid-area: stnd;
    display: flex;
    flex-direction: row;
    @extend %t-body;

    a {
        color: $offBlack;
    }

    @include media('<tablet') {
        // XXX: Gap is u(2) but combines with `padding-bottom` of items
        margin-top: u(1);

        .tabbed-content--content-below & {
            margin-top: u(3.5);
        }
    }

    @include media('>=tablet') {
        margin-top: u(2);

        .tabbed-content--content-below & {
            margin-top: u(5.5);
        }
    }

    @include media('>=desktop') {
        .tabbed-content--content-below & {
            margin-top: u(6.5);
        }
    }
}

.tabbed-content__primary-content-list__item {
    visibility: hidden;
    margin-right: -100%;
    width: 100%;

    &.is-active {
        visibility: visible;
    }

    @include media('>=tablet') {
        .tabbed-content--content-below & {
            columns: 2;
            column-gap: $gridGutterTablet;
        }
    }

    @include media('>=desktop') {
        .tabbed-content--content-below & {
            column-gap: $gridGutterDesktop;
        }
    }
}

.tabbed-content__primary-content-list__cta-wrapper {
    margin-top: u(2);

    @include media('>=tablet') {
        margin-top: u(6);
    }
}

.tabbed-content__primary-content-list__cta {
    @extend %t-button;
    display: inline-flex;
    align-items: center;
    gap: u(2);

    .icon {
        width: u(3);
        height: u(3);
    }

    /* stylelint-disable selector-max-compound-selectors */
    .mod-no-flexgap & > * + * {
        margin-left: u(2);
    }
    /* stylelint-enable selector-max-compound-selectors */

    // HACK: Add specificity to override `u-rich-text` link styles
    &:not(.foo) {
        text-decoration: none;

        &:hover,
        &:active,
        &:focus {
            text-decoration: underline;
        }
    }
}

.tabbed-content__secondary-content-list {
    grid-area: cont;
    @include break-out-of-gutters;
    margin-top: u(2);

    @include media('>=tablet') {
        margin-top: u(3);
    }

    @include media('>=desktop') {
        margin-top: u(4);
    }
}

.tabbed-content__secondary-content-list__item {
    display: none;

    &.is-active {
        display: block;
    }
}
