.theme-grid {
    @include gutter-padding;
    padding-bottom: u(12);

    background: $offBlack;

    color: $white;

    @include media('>=tablet') {
        padding-top: u(10);
        padding-bottom: u(10);
    }
}

.theme-grid__inner {
    @include clamp;

    @include media('>=tablet') {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: $gridGutterTablet;

        /* stylelint-disable selector-max-compound-selectors */
        .mod-no-flexgap & > * + * {
            margin-left: $gridGutterTablet;
        }
        /* stylelint-enable selector-max-compound-selectors */
    }

    @include media('>=tablet') {
        gap: $gridGutterDesktop;

        /* stylelint-disable selector-max-compound-selectors */
        .mod-no-flexgap & > * + * {
            margin-left: $gridGutterDesktop;
        }
        /* stylelint-enable selector-max-compound-selectors */
    }
}

.theme-grid__header {
    display: flex;
    align-items: center;
    justify-content: center;

    @include media('>=tablet') {
        flex-basis: grid-span(3, 12, $gridGutterTablet);
        position: relative;

        &::after {
            display: block;
            padding-top: 100%;

            content: '';
        }
    }

    @include media('>=desktop') {
        flex-basis: grid-span(3, 12, $gridGutterDesktop);
    }
}

.theme-grid__heading {
    @extend %t-h1;

    @include media('>=tablet') {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;

        transform: translateY(-50%);
        text-align: center;
    }
}

.theme-grid__main {
    @include media('<tablet') {
        margin: u(4.75) 0 0 0;
    }

    @include media('>=tablet') {
        flex-grow: 1;
    }
}

.theme-grid__list {
    margin: 0 grid-span(1, 6, $gridGutterMobile);
    padding: 0 $gridGutterMobile;
    display: flex;
    flex-direction: column;
    gap: u(2.5);

    @include media('>=tablet') {
        margin: 0;
        padding: 0;
        flex-flow: row wrap;
        justify-content: space-between;
        gap: u(4) $gridGutterTablet;
    }

    @include media('>=desktop') {
        gap: u(5) $gridGutterDesktop;
        justify-content: normal;
    }
}

.theme-grid__item {
    @include media('>=tablet') {
        flex-basis: grid-span(3, 9, $gridGutterTablet);
    }

    @include media('>=desktop') {
        flex-basis: grid-span(3, 9, $gridGutterDesktop);
    }
}
