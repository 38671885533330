.section-nav {
    padding: u(0.5) 0 u(4) 0;

    @include media('>=tablet') {
        padding: u(7) 0 u(5.75) 0;
    }

    @include media('>=desktop') {
        padding: u(7) 0 u(8) 0;
    }
}

.section-nav__inner {
    @include clamp;
    @include gutter-padding;
}

.section-nav__header {
    @include grid;
    grid-template-areas:
        'title title title title title title'
        'main  main  main  main  main  main ';

    .section-nav--with-arrows & {
        grid-template-areas:
            'title title title nav   nav   nav  '
            'main  main  main  main  main  main ';
    }

    @include media('>=tablet') {
        grid-template-areas:
            'title title title title title title title title title title title title'
            'main  main  main  main  main  main  main  main  main  main  main  main ';

        .section-nav--with-arrows & {
            grid-template-areas:
                'title title title title title title title title title nav   nav   nav  '
                'main  main  main  main  main  main  main  main  main  main  main  main ';
        }
    }
}

.section-nav__title {
    @extend %t-h2-special;
    grid-area: title;
}

.section-nav__buttons {
    display: none;

    .section-nav--with-arrows & {
        grid-area: nav;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: u(2.5);

        /* stylelint-disable selector-max-compound-selectors */
        .mod-no-flexgap & > * + * {
            margin-left: u(2.5);
        }
        /* stylelint-enable selector-max-compound-selectors */
    }
}

.section-nav__button {
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;

    &:hover,
    &:focus {
        opacity: 0.7;
    }

    &:disabled {
        opacity: 0.2;
    }

    .icon {
        width: u(4);
        height: u(4);
    }

    &--prev {
        .icon {
            transform: rotateZ(180deg);
        }
    }

    &--next {

    }
}

.section-nav__main {
    grid-area: main;
    margin-top: u(2);

    @include media('>=tablet') {
        margin-top: u(5.75);
    }

    @include media('>=desktop') {
        margin-top: u(6);
    }
}

.section-nav__list {
    margin: 0 #{-$pageGutterMobile};
    padding: 0 $pageGutterMobile;
    scroll-padding-left: $pageGutterMobile;
    display: flex;
    flex-direction: row;
    gap: u(2);
    @include scrollable-x;

    /* stylelint-disable selector-max-compound-selectors */
    .mod-no-flexgap & > * + * {
        margin-left: u(2);
    }
    /* stylelint-enable selector-max-compound-selectors */

    @include media('>=tablet') {
        margin: 0 #{-$pageGutterTablet};
        padding: 0 $pageGutterTablet;
        scroll-padding-left: $pageGutterTablet;
        gap: u(5.5);

        /* stylelint-disable selector-max-compound-selectors */
        .mod-no-flexgap & > * + * {
            margin-left: u(5.5);
        }
        /* stylelint-enable selector-max-compound-selectors */
    }

    @include media('>=desktop') {
        margin: 0 #{-$pageGutterDesktop};
        padding: 0 $pageGutterDesktop;
        scroll-padding-left: $pageGutterDesktop;
        gap: u(6.25);

        /* stylelint-disable selector-max-compound-selectors */
        .mod-no-flexgap & > * + * {
            margin-left: u(6.25);
        }
        /* stylelint-enable selector-max-compound-selectors */
    }
}

.section-nav__item {
    flex: 0 0 auto;
    scroll-snap-align: start;
    padding: 0 0 u(1) 0;
}

.section-nav__link {
    @extend %t-h3-alt;
    white-space: nowrap;
    text-transform: lowercase;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
    color: $grey;

    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
    }

    &.is-active {
        color: $offBlack;
    }

    &:not(.is-active):hover,
    &:not(.is-active):focus {
        opacity: 0.7;
    }
}
