@use 'sass:math';

.get-involved-card {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include media('>=tablet') {
        flex-direction: row;
        align-items: normal;
    }
}

.get-involved-card__image-wrapper {
    @include media('<tablet') {
        width: 200px;
        margin: u(3.75) auto;
    }

    @include media('>=tablet') {
        flex: 0 0 percentage(math.div(140, 500));
        margin-right: $gridGutterTablet;
    }

    @include media('>=desktop') {
        margin-right: $gridGutterDesktop;
    }
}

.get-involved-card__image,
.get-involved-card__image > img {
    display: block;
    width: 100%;
    height: auto;

    border-radius: 50%;
}

.get-involved-card__inner {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.get-involved-card__title {
    @extend %t-h3;

    strong {
        @extend %t-h3-alt;
    }
}

.get-involved-card__standfirst {
    @extend %t-body;
    margin: u(2.5) 0 0 0;
    flex-grow: 1;

    color: $darkGrey;

    @include media('>=tablet') {
        margin-top: u(3.5);
    }

    @include media('>=desktop') {
        margin-top: u(4.25);
    }
}

.get-involved-card__cta-wrapper {
    margin: u(3.75) 0 0 0;

    @include media('>=tablet') {
        margin-top: u(5);
    }

    @include media('>=desktop') {
        margin-top: u(6.25);
    }
}

.get-involved-card__cta {
    @extend %t-button;
    display: flex;
    align-items: center;
    gap: u(2);

    .icon {
        width: u(3);
        height: u(3);
    }

    /* stylelint-disable selector-max-compound-selectors */
    .mod-no-flexgap & > * + * {
        margin-left: u(2);
    }
    /* stylelint-enable selector-max-compound-selectors */
}
