.social-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: u(4);

    /* stylelint-disable selector-max-compound-selectors */
    .mod-no-flexgap & > * + * {
        margin-left: u(4);
    }
    /* stylelint-enable selector-max-compound-selectors */

    @include media('>=tablet') {
        margin-top: u(5);
    }
}

.social-list__item {

}

.social-list__link {
    opacity: 1;
    transition: opacity 0.2s ease;

    &:hover,
    &:focus {
        opacity: 0.7;
    }

    &:focus-visible {
        box-shadow: 0 u(0.5) 0 0 $ui-highlight;
    }
}
