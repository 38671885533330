@use 'sass:math';

.article-card {
    height: 100%;

    background: $white;

    border: 1px solid $borderGrey;

    color: $offBlack;

    &--large {

    }

    &--dark {
        background: $black;

        color: $white;
    }
}

.article-card__link {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;

    opacity: 1;
    transition: opacity 0.2s ease-in-out;

    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        opacity: 0.7;
    }
}

.article-card__media-wrapper {
    position: relative;
    flex-shrink: 0;

    background: $grey;

    &::after {
        display: block;
        padding-top: percentage(math.div(9, 16));

        content: '';
    }

    @include media('>=tablet') {
        .article-card--large & {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            width: grid-span(8, 12, $gridGutterTablet, $gridGutterTablet);

            /* stylelint-disable max-nesting-depth */
            &::after {
                display: none;
            }
            /* stylelint-enable max-nesting-depth */
        }
    }

    @include media('>=desktop') {
        .article-card--large & {
            width: grid-span(4, 12, $gridGutterDesktop, $gridGutterDesktop);
        }
    }
}

.article-card__image,
.article-card__image > img,
.article-card__video {
    display: block;
    width: 100%;
    height: 100%;

    object-fit: cover;
}

.article-card__image,
.article-card__video {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
}

.article-card__video {
    z-index: 2;
}

.article-card__content-wrapper {
    display: flex;
    flex-direction: column;
    padding: u(2.5);
    flex-grow: 1;

    @include media('>=tablet') {
        padding: u(3.75);

        .article-card--large & {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: grid-span(4, 12, $gridGutterTablet);
        }
    }

    @include media('>=desktop') {
        .article-card--large & {
            width: grid-span(4, 12, $gridGutterDesktop);
        }
    }
}

.article-card__title {
    @extend %t-h5-special;
    margin: 0 0 u(3.5) 0;
    min-height: 2.88em;

    line-height: math.div(20, 16);
}

.article-card__tag-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 0 u(1) 0;

    @include media('>=tablet') {
        margin-bottom: u(3);
    }

    @include media('>=desktop') {
        margin-bottom: u(3.75);
    }
}

.article-card__tag {
    @extend %t-tag;

    &--special {
        text-align: right;
        color: $green;
    }
}

.article-card__standfirst {
    @extend %t-small-body;

    @include media('<tablet') {
        @include line-clamp(3);
    }

    @include media('>=tablet') {
        .article-card:not(.article-card--large) & {
            @include line-clamp(4);
        }
    }
}
