.logo {
    display: block;
}

.logo__link {
    @include hide-text-alt;
    display: block;
    width: 210px;
    height: 20px;

    background: url('../svg/reboot_logo_black.svg') 0 0 no-repeat;
    background-size: 100% auto;

    opacity: 1;
    transition: all 0.1s ease-in-out;

    &:focus-visible {
        box-shadow: 0 u(0.5) 0 0 $ui-highlight;
    }

    &:hover {
        opacity: 0.7;
    }

    .logo--light & {
        background-image: url('../svg/reboot_logo_white.svg');
    }

    @include media('>=tablet') {
        width: 380px;
        height: 36px;
    }
}
