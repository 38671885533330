.admin-toolbar {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: $z-admin-toolbar;
    padding: u(1);

    background: $offBlack;

    border-radius: 0 u(0.5) 0 0;

    @extend %t-tag;
    color: $white;
}

.admin-toolbar__list {

}

.admin-toolbar__item {

}

.admin-toolbar__link {
    display: flex;
    align-items: center;
    gap: u(1);

    transition: opacity 0.2s ease-in-out;

    &:hover,
    &:focus {
        opacity: 0.7;
        text-decoration: none;
    }

    .icon {
        width: u(2);
        height: u(2);
    }

    /* stylelint-disable selector-max-compound-selectors */
    .mod-no-flexgap & > * + * {
        margin-left: u(1);
    }
    /* stylelint-enable selector-max-compound-selectors */
}
